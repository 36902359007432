/*
This is a component version of the ReadSpeaker javascript file.
*/

import React, { useEffect } from "react";
import { Box } from "@island.is/core";

interface TextToVoiceProps {
  children: React.ReactNode;
}

const TextToVoice = ({ children }: TextToVoiceProps) => {
  useEffect(() => {
    var oHead = document.getElementsByTagName("HEAD").item(0);
    var oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.id = "rs_req_Init";
    oScript.src = `//cdn1.readspeaker.com/script/11330/webReader/webReader.js`;

    oHead.appendChild(oScript);

    return function cleanup() {
      ReadSpeaker.q(function () {
        if (rspkr.ui.getActivePlayer()) {
          rspkr.ui.getActivePlayer().close();
        }
      });
      var cScript = document.getElementById("rs_req_Init");
      var cScriptC = document.getElementById("req_Core");
      // var cScriptS = document.getElementById("rsmod_Styles");
      if (cScript) {
        cScript.parentNode.removeChild(cScript);
        cScriptC.parentNode.removeChild(cScriptC);
        // cScriptS.parentNode.removeChild(cScriptS);
      }
    };
  }, []);

  return (
    <Box>
      <Box paddingTop={2}>
        <div id="readspeaker_button1" className="rs_skip rsbtn noprint">
          <a
            rel="nofollow"
            className="rsbtn_play"
            title="Hlusta á texta með ReadSpeaker webReader"
            href={`//app-na.readspeaker.com/cgi-bin/rsent?customerid=11330&amp;lang=is_is&amp;readid=main-content&amp;url=${encodeURIComponent(
              document.location.href
            )}`}
          >
            <span className="rsbtn_left rsimg rspart">
              <span className="rsbtn_text">
                <span>Hlusta</span>
              </span>
            </span>
            <span className="rsbtn_right rsimg rsplay rspart"></span>
          </a>
        </div>
      </Box>
      <Box id="main-content" className="rs_preserve">
        {children}
      </Box>
    </Box>
  );
};

export default TextToVoice;
