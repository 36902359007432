import React from "react";
import { Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";
import parse from "html-react-parser";
import { Bullet } from "@island.is/core";

import { addTooltips, makeTooltips } from "../../../../utils";

interface ListSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
  /*
  List of words and definitions for tooltips.
  */
  Tooltips?: any;
  /*
  To show or not show tooltips in this component.
   */
  ShowTooltips?: boolean;
}

const ListSlice = ({
  Data,
  Tooltips = "",
  ShowTooltips = true,
}: ListSliceProps) => {
  return (
    <Box>
      {/* Skoðar hvort Tooltips sé array og hvort það sé tómt */}
      {(!Array.isArray(Tooltips) || Tooltips.length === 0 || !ShowTooltips) &&
        Data.map((data: any, index: number) => (
          <Box
            key={index}
            paddingBottom={1}
            paddingTop={1}
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            <Bullet>
              {parse(RichText.asText(data.list).replace(/\s+/g, " ").trim())}
            </Bullet>
          </Box>
        ))}

      {Array.isArray(Tooltips) &&
        ShowTooltips &&
        Tooltips.length > 0 &&
        Data.map((data: any, index: number) => (
          <Box
            key={index}
            paddingTop={1}
            paddingBottom={1}
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            <Bullet>
              {parse(
                addTooltips(
                  RichText.asText(data.list).replace(/\s+/g, " ").trim(),
                  makeTooltips(Tooltips)
                )
              )}
            </Bullet>
          </Box>
        ))}
    </Box>
  );
};

export default ListSlice;
