/*
This is a htmlSerilizer from Prismic.
https://prismic.io/docs/technologies/html-serializer-reactjs

Here we add id to titles in the text displayed in Kaflar so when
we click on a link in TableOfContent we get moved to the correct place. 
*/

import React from "react";
import { Elements } from "prismic-reactjs";
import { colors } from "@island.is/constants";

import { cleanText } from "../../cleanText";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

export const addIdSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  var props = {};
  switch (type) {
    case Elements.heading1: // Heading 1 add Id of title
      var temp1 = cleanText(children);
      props = { id: temp1, style: { color: colors.seagreen600 } };
      return React.createElement(
        "h1",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading2: // Heading 2 add Id of title
      var temp2 = cleanText(children);
      props = { id: temp2, style: { color: colors.seagreen600 } };
      return React.createElement(
        "h2",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading3: // Heading 3 add Id of title
      var temp3 = cleanText(children);
      props = { id: temp3, style: { color: colors.seagreen600 } };
      return React.createElement(
        "h3",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading4: // Heading 4 add Id of title
      var temp4 = cleanText(children);
      props = { id: temp4 };
      return React.createElement(
        "h4",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading5: // Heading 5 add Id of title
      var temp5 = cleanText(children);
      props = { id: temp5 };
      return React.createElement(
        "h5",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading6: // Heading 6 add Id of title
      var temp6 = cleanText(children);
      props = { id: temp6 };
      return React.createElement(
        "h6",
        propsWithUniqueKey(props, key),
        children
      );

    default:
      return null;
  }
};

export default addIdSerializer;
