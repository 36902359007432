import React from "react";
import { Bullet, Box, Text } from "@island.is/core";
import parse from "html-react-parser";
import { addTooltips, makeTooltips } from "../../../../../../utils";

interface BulletListProps {
  data: any;
  Tooltips?: any;
}

const BulletList = ({ data, Tooltips = "" }: BulletListProps) => {
  return (
    <Box>
      {data.type.includes("heading") && (
        <Box>
          {data.type === "heading6" ? (
            <Text variant="h5" color="seagreen600">
              {parse(data.text.replace(/\s+/g, " ").trim())}
            </Text>
          ) : (
            <Text variant="h4" color="seagreen600">
              {parse(data.text.replace(/\s+/g, " ").trim())}
            </Text>
          )}
        </Box>
      )}

      {!data.type.includes("heading") && (
        <Bullet>
          {parse(
            addTooltips(
              data.text.replace(/\s+/g, " ").trim(),
              makeTooltips(Tooltips)
            )
          )}
        </Bullet>
      )}
    </Box>
  );
};

export default BulletList;
