import React from "react";
import { Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";
import { colors } from "@island.is/constants";

import { addIdSerializer, seaGreenSerializer } from "../../../../utils";

interface TitleSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
  /*
  Type of title component.
  */
  Type: "title" | "title_toc";
}

const TitleSlice = ({ Data, Type }: TitleSliceProps) => (
  <Box>
    {Type === "title" && (
      <Box>
        {Data.line && (
          <Box
            style={{
              borderBottom: "2px solid " + colors.seagreen400,
            }}
          >
            <RichText render={Data.title} htmlSerializer={seaGreenSerializer} />
          </Box>
        )}
        {!Data.line && (
          <Box>
            <RichText render={Data.title} htmlSerializer={seaGreenSerializer} />
          </Box>
        )}
      </Box>
    )}

    {Type === "title_toc" && (
      <Box>
        {Data.line && (
          <Box
            style={{
              borderBottom: "2px solid " + colors.seagreen400,
            }}
          >
            <RichText render={Data.title} htmlSerializer={addIdSerializer} />
          </Box>
        )}
        {!Data.line && (
          <Box>
            <RichText render={Data.title} htmlSerializer={addIdSerializer} />
          </Box>
        )}
      </Box>
    )}
  </Box>
);

export default TitleSlice;
