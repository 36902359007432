export const colors = {
  seagreen100: "#00f8ea",
  seagreen200: "#00ded2",
  seagreen300: "#00c5ba",
  seagreen400: "#00aba2",
  seagreen500: "#00928a",
  seagreen600: "#007872",
  seagreen700: "#005f5a",
  white: "#FFFFFF",
  sg90: "#f4fbfb",
  sg100: "#e5f6f5",
  sg200: "#cceeec",
  sg300: "#b2e5e3",
  sg400: "#99ddd9",
  sg500: "#7fd5d0",
  sg600: "#66ccc7",
  sg700: "#4ccebd",
  sg800: "#32bbb4",
  sg900: "#19b3ab",
  sg1000: "#00aba2",
};
/**
 * #ccffeb
 * */

export const fonts = {
  largeFont: "64px",
  medFont: "46px",
  smallFont: "24px",
};
