/*
This is for Search:
Gets data and boolean values from the search page.
remove() removes objects from the array that dont match the booleans.

Then it finds all chapters that include a specific SearchTerm word and
sorts them so the output is cleaner.
*/

/*
Formál: 0
Almennur hluti: 1
Greinasvið: 2
*/
function remove(list: any, stat: any) {
  var i = 0
  while (i < list.length) {
    switch (true) {
      case stat[0] === false && list[i].data.type === "Formali":
        list.splice(i, 1)
        i--
        break

      case stat[1] === false && list[i].data.type === "Almennur-Hluti":
        list.splice(i, 1)
        i--
        break

      case stat[2] === false && list[i].data.type === "Greinasvid":
        list.splice(i, 1)
        i--
        break
    }
    i++
  }
  return list
}

function findSearchValues(data: any, SearchTerm: string, stat: any) {
  var SearchList = []
  var temp: string
  var i = 0
  var list = remove(data, stat)

  while (i < list.length) {
    temp = JSON.stringify(list[i])
    temp = temp.toLowerCase()
    if (temp.includes(SearchTerm.toLowerCase())) {
      SearchList.push(list[i])
    }

    SearchList.sort(
      (firstItem, secondItem) => firstItem.number - secondItem.number
    )
    i++
  }
  return SearchList
}

export default findSearchValues
