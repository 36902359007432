/*
This is for displaying the Kaflar.

*/

export default function getKaflarLValues(doc: any) {
  var HeaderProps: any
  var FooterProps: any
  var ChaptersProps = []
  var AllChapterNames = []
  var TooltipsProps: any

  var i = 0
  var leni = doc.length
  while (i < leni) {
    switch (true) {
      case doc[i].type === "header":
        HeaderProps = doc[i].data
        break

      case doc[i].type === "footer":
        FooterProps = doc[i].data.body
        break

      case doc[i].type === "tooltips":
        TooltipsProps = doc[i].data.body
        break

      // index heitir href hér svo auðvelt sé að nota það með sortNav
      case doc[i].type === "adalnamskrakafli":
        ChaptersProps.push(doc[i].data)
        AllChapterNames.push({
          index: doc[i].data.number,
          href: "/adalnamskra-leikskola/" + doc[i].data.dir[0].text,
          title: doc[i].data.name[0].text,
          type: doc[i].data.type,
        })
        break
    }
    AllChapterNames.sort(
      (firstItem, secondItem) => firstItem.index - secondItem.index
    )
    i++
  }

  return [
    HeaderProps,
    FooterProps,
    ChaptersProps,
    AllChapterNames,
    TooltipsProps,
  ]
}
