import FB from "./fb.svg"
import LI from "./linkedin.svg"
import TW from "./twitter.svg"
import EM from "./email.svg"
import SI from "./si.svg"
import MMS from "./mms.svg"

export default function Footer() {
  return (
    <div className="footer-blog-container">
      <div className="social">
        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadalnamskra.is%2Fadalnamskra-grunnskola&quote=A%C3%B0aln%C3%A1mskr%C3%A1%20grunnsk%C3%B3la">
          <img src={FB} alt="" />
        </a>
        <a href="/">
          <img src={EM} alt="" />
        </a>
        <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fadalnamskra.is%2Fadalnamskra-grunnskola&text=Twitter">
          <img src={TW} alt="" />
        </a>
        <a href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fadalnamskra.is%2Fadalnamskra-grunnskola">
          <img src={LI} alt="" />
        </a>
      </div>
      <div className="below">
        <div className="images">
          <img src={MMS} alt="" />
          <img src={SI} alt="" />
        </div>
        <div className="links">
          <a href="https://mms.is/hafa-samband">Hafa Samband</a>
          <a href="/greinar/um-rafraena-adalnamskra">Um rafræna aðalnámskrá</a>
          <a href="/greinar/spurt-og-svarad">Spurt og svarað</a>
        </div>
      </div>
    </div>
  )
}
