/*
Checks to see if there are 
*/

import React from "react";
import { Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";
import parse from "html-react-parser";

import { addTooltips, makeTooltips } from "../../../../utils";

interface TextSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
  /*
  List of words and definitions for tooltips.
  */
  Tooltips?: any;
  /*
  To show or not show tooltips in this component.
   */
  ShowTooltips?: boolean;
}

const TextSlice = ({ Data, Tooltips, ShowTooltips = true }: TextSliceProps) => {
  return (
    <Box>
      {/* Skoðar hvort Tooltips sé array og hvort það sé tómt */}
      {(!Array.isArray(Tooltips) || Tooltips.length === 0 || !ShowTooltips) &&
        Data.map((data: any, index: number) => (
          <Box
            key={index}
            paddingBottom={1}
            paddingTop={1}
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            {parse(RichText.asText(data.text).replace(/\s+/g, " ").trim())}
          </Box>
        ))}

      {Array.isArray(Tooltips) &&
        ShowTooltips &&
        Tooltips.length > 0 &&
        Data.map((data: any, index: number) => (
          <Box
            key={index}
            paddingBottom={1}
            paddingTop={1}
            className={"printtext"}
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            {parse(
              addTooltips(
                RichText.asText(data.text).replace(/\s+/g, " ").trim(),
                makeTooltips(Tooltips)
              )
            )}
          </Box>
        ))}
    </Box>
  );
};

export default TextSlice;
