import "!style-loader!css-loader?{\"modules\":false,\"url\":false}!./Checkbox.treat.2qoeTpX.css";
export var checkMarkWidth = 16;
export var container = '_2y-W6';
export var filled = 'eTYCg';
export var large = '_1l8dB';
export var input = '_19PyE';
export var label = 'rENgs';
export var labelText = '_2R46I';
export var checkboxDisabled = '_2B0Th';
export var largeLabel = 'GSo4A';
export var checkbox = '_3yn3l';
export var checkboxChecked = '_3R_Qo';
export var checkboxLabelDisabled = '_3jNFn';
export var checkboxError = '_30LmR';
export var errorMessage = 'cw8Y6';
export var tooltipContainer = '_3hvuG';
export var tooltipLargeContainer = '_3qNYI';