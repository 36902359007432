/*
  HeaderLink: Buttons inside the Header component.
  Unable to map DropDownMenu with the onClick function.
  Thats why they are hard coded and not store on Prismic.
  */
import React, { Suspense } from "react"
import {
  GridContainer,
  GridRow,
  GridColumn,
  Box,
  Button,
  Inline,
  DropdownMenu,
} from "@island.is/core"
import { RichText } from "prismic-reactjs"
import { useHistory, useLocation } from "react-router-dom"

import * as styles from "./HeaderLink.treat"
import { background } from "lib/island.is/core/lib/Box/useBoxStyles.treat"

const GoogleTranslate = React.lazy(
  () => import("../GoogleTranslate/GoogleTranslate")
)

interface HeaderLinkProps {
  /*
  Data in Prismic format.
  */
  Data: any
}

const HeaderLink = ({
  Data = [{ dir: "/", text: "Forsíða", error: true }],
}: HeaderLinkProps) => {
  let history = useHistory()
  let location = useLocation()
  const pathnameParts = location.pathname.split("/") // Split the pathname into an array of parts
  const topLevelPage = `/${pathnameParts[1]}` // Extract the top-level page

  if (Data.length === 0) {
    Data = [{ dir: "/", text: "Forsíða", error: true }]
  }
  return (
    <Box className={styles.root} paddingTop={1}>
      <GridContainer>
        <GridRow>
          <GridColumn span="12/12">
            <Inline space="auto">
              {Data.map((data: any, index: number) => (
                <Box paddingRight={2} key={index} paddingBottom={1}>
                  <span
                    className={
                      topLevelPage === RichText.asText(data.dir) ? "topNav" : ""
                    }
                  >
                    <Button
                      colorScheme={"default"}
                      iconType="filled"
                      onClick={() =>
                        history.push(
                          data.error ? data.dir : RichText.asText(data.dir)
                        )
                      }
                      preTextIconType="filled"
                      size="small"
                      type="button"
                      variant="primary"
                    >
                      {data.error ? data.text : RichText.asText(data.text)}
                    </Button>
                  </span>
                </Box>
              ))}
              {/* <Box paddingRight={2} paddingBottom={1}>
                <DropdownMenu
                  items={[
                    {
                      onClick: () =>
                        history.push("/greinar/heilbrigdi-og-velferd"),
                      title: "Heilbrigði og velferð",
                    },
                    {
                      onClick: () => history.push("/greinar/jafnretti"),
                      title: "Jafnrétti",
                    },
                    {
                      onClick: () => history.push("/lasi/kafli-1"),
                      title: "Læsi",
                    },
                    {
                      onClick: () =>
                        history.push("/greinar/lydradi-og-mannrettindi"),
                      title: "Lýðræði og mannréttindi",
                    },
                    {
                      onClick: () => history.push("/greinar/sjalfbarni"),
                      title: "Sjálfbærni",
                    },
                    {
                      onClick: () => history.push("/greinar/skopun"),
                      title: "Sköpun",
                    },
                  ]}
                  title="Grunnþættir"
                />
              </Box> */}
              <Box paddingRight={2} paddingBottom={1}>
                <DropdownMenu
                  items={[
                    {
                      onClick: () => history.push("/greinar/adalnamskra"),
                      title: "Aðalnámskrá",
                    },
                    {
                      onClick: () => history.push("/greinar/grunnthaettir"),
                      title: "Grunnþættir menntunar",
                    },
                    {
                      onClick: () =>
                        history.push("/greinar/hugtok-og-utskyringar"),
                      title: "Hugtök og útskýringar",
                    },
                    {
                      onClick: () => history.push("/greinar/haefni"),
                      title: "Hæfni",
                    },
                    {
                      onClick: () => history.push("/greinar/haefnividmid"),
                      title: "Hæfniviðmið",
                    },
                    {
                      onClick: () => history.push("/greinar/isat"),
                      title: "ÍSAT",
                    },
                    {
                      onClick: () => history.push("/greinar/leidsagnarmat"),
                      title: "Leiðsagnarmat",
                    },
                    {
                      onClick: () => history.push("/greinar/leikskoli"),
                      title: "Leikskóli",
                    },
                    {
                      onClick: () => history.push("/greinar/lykilhaefni"),
                      title: "Lykilhæfni",
                    },
                    {
                      onClick: () => history.push("/greinar/matsvidmid"),
                      title: "Matsviðmið",
                    },
                    {
                      onClick: () => history.push("/greinar/namsmat"),
                      title: "Námsmat",
                    },
                  ]}
                  title="Stuðningsefni"
                />
              </Box>
              <Box paddingRight={2} paddingBottom={1}>
                <Suspense fallback={<div></div>}>
                  <Button
                    colorScheme="default"
                    iconType="filled"
                    onClick={() => history.push("/endurskodun-greinasvida")}
                    preTextIconType="filled"
                    size="small"
                    type="button"
                    variant="primary"
                  >
                    Endurskoðun
                  </Button>
                </Suspense>
              </Box>
              <Box paddingRight={2} paddingBottom={1}>
                <Suspense fallback={<div></div>}>
                  <GoogleTranslate />
                </Suspense>
              </Box>
            </Inline>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  )
}

export default HeaderLink
