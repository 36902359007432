/*
Checks to see if there are 

*/

import React from "react";
import { Box, GridContainer, GridRow } from "@island.is/core";

import { ThreeList } from "./components";

interface ThreeListSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
  /*
  List of words and definitions for tooltips.
  */
  Tooltips?: any;
  /*
  To show or not show tooltips in this component.
   */
  ShowTooltips?: boolean;
}

const ThreeListSlice = ({
  Data,
  Tooltips = "",
  ShowTooltips = true,
}: ThreeListSliceProps) => {
  return (
    <Box>
      {/* Skoðar hvort Tooltips sé array og hvort það sé tómt */}
      {(!Array.isArray(Tooltips) || Tooltips.length === 0 || !ShowTooltips) &&
        Data.map((data: any, index: number) => (
          <GridContainer key={index}>
            <GridRow>
              <ThreeList Data={data.textone} />
              <ThreeList Data={data.texttwo} />
              <ThreeList Data={data.textthree} />
            </GridRow>
          </GridContainer>
        ))}

      {Array.isArray(Tooltips) &&
        ShowTooltips &&
        Tooltips.length > 0 &&
        Data.map((data: any, index: number) => (
          <GridContainer key={index}>
            <GridRow>
              <ThreeList Data={data.textone} Tooltips={Tooltips} />
              <ThreeList Data={data.texttwo} Tooltips={Tooltips} />
              <ThreeList Data={data.textthree} Tooltips={Tooltips} />
            </GridRow>
          </GridContainer>
        ))}
    </Box>
  );
};

export default ThreeListSlice;
