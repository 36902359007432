export const items = [
  { label: "grunnskóli", value: "grunnskóli" },
  { label: "nemendur", value: "nemendur" },
  { label: "bekkur", value: "bekkur" },
  { label: "aðalnámskrá", value: "aðalnámskrá" },
  { label: "nemandi", value: "nemandi" },
  { label: "mikilvægt", value: "mikilvægt" },
  { label: "íslenska", value: "íslenska" },
  { label: "hvernig", value: "hvernig" },
  { label: "þekkingu", value: "þekkingu" },
  { label: "umhverfi", value: "umhverfi" },
  { label: "greinasvið", value: "greinasvið" },
  { label: "menningarmálaráðuneyti", value: "menningarmálaráðuneyti" },
  { label: "almennur", value: "almennur" },
  { label: "tækifæri", value: "tækifæri" },
  { label: "matsviðmið", value: "matsviðmið" },
  { label: "menntun", value: "menntun" },
  { label: "hæfniviðmið", value: "hæfniviðmið" },
  { label: "upplýsingar", value: "upplýsingar" },
  { label: "námsmat", value: "námsmat" },
  { label: "útskýrt", value: "útskýrt" },
  { label: "mismunandi", value: "mismunandi" },
  { label: "viðfangsefni", value: "viðfangsefni" },
  { label: "skapandi", value: "skapandi" },
  { label: "kennslu", value: "kennslu" },
  { label: "fjallað", value: "fjallað" },
  { label: "markmið", value: "markmið" },
  { label: "skilning", value: "skilning" },
  { label: "hæfniviðmiðum", value: "hæfniviðmiðum" },
  { label: "samhengi", value: "samhengi" },
  { label: "annarra", value: "annarra" },
  { label: "verkefni", value: "verkefni" },
  { label: "skólastarfi", value: "skólastarfi" },
  { label: "framhaldsskóla", value: "framhaldsskóla" },
  { label: "nemanda", value: "nemanda" },
  { label: "viðkomandi", value: "viðkomandi" },
  { label: "samræmi", value: "samræmi" },
  { label: "skólanámskrá", value: "skólanámskrá" },
  { label: "foreldra", value: "foreldra" },
  { label: "áhersla", value: "áhersla" },
  { label: "stærðfræði", value: "stærðfræði" },
  { label: "samkvæmt", value: "samkvæmt" },
  { label: "kennarar", value: "kennarar" },
  { label: "áherslu", value: "áherslu" },
  { label: "kennara", value: "kennara" },
  { label: "lykilhæfni", value: "lykilhæfni" },
  { label: "námsgreinar", value: "námsgreinar" },
  { label: "aðferðir", value: "aðferðir" },
  { label: "skólans", value: "skólans" },
  { label: "velferð", value: "velferð" },
  { label: "niðurstöður", value: "niðurstöður" },
  { label: "reynslu", value: "reynslu" },
  { label: "þátttöku", value: "þátttöku" },
  { label: "upplýsinga", value: "upplýsinga" },
  { label: "samvinnu", value: "samvinnu" },
  { label: "menningu", value: "menningu" },
  { label: "viðeigandi", value: "viðeigandi" },
  { label: "aðferðum", value: "aðferðum" },
  { label: "táknmáli", value: "táknmáli" },
  { label: "viðmiðum", value: "viðmiðum" },
  { label: "námssvið", value: "námssvið" },
  { label: "hlutverk", value: "hlutverk" },
  { label: "samskiptum", value: "samskiptum" },
  { label: "aðstæður", value: "aðstæður" },
  { label: "virðingu", value: "virðingu" },
  { label: "jafnframt", value: "jafnframt" },
  { label: "táknmál", value: "táknmál" },
  { label: "náttúru", value: "náttúru" },
  { label: "tengjast", value: "tengjast" },
  { label: "viðfangsefnum", value: "viðfangsefnum" },
  { label: "samstarfi", value: "samstarfi" },
  { label: "foreldrar", value: "foreldrar" },
  { label: "þekkingar", value: "þekkingar" },
  { label: "daglegu", value: "daglegu" },
  { label: "tengslum", value: "tengslum" },
  { label: "menntagildi", value: "menntagildi" },
  { label: "hlutverki", value: "hlutverki" },
  { label: "heilbrigði", value: "heilbrigði" },
  { label: "sjálfbærni", value: "sjálfbærni" },
  { label: "byggist", value: "byggist" },
  { label: "orðaforða", value: "orðaforða" },
  { label: "jafnrétti", value: "jafnrétti" },
  { label: "fjölbreyttar", value: "fjölbreyttar" },
  { label: "hugmyndir", value: "hugmyndir" },
  { label: "þjálfun", value: "þjálfun" },
  { label: "nemandinn", value: "nemandinn" },
  { label: "stærðfræðinnar", value: "stærðfræðinnar" },
  { label: "menntunar", value: "menntunar" },
  { label: "fjölbreyttum", value: "fjölbreyttum" },
  { label: "samfélaginu", value: "samfélaginu" },
  { label: "markmiðum", value: "markmiðum" },
  { label: "nemandans", value: "nemandans" },
  { label: "þekking", value: "þekking" },
  { label: "skipulag", value: "skipulag" },
  { label: "lýðræði", value: "lýðræði" },
  { label: "tungumál", value: "tungumál" },
  { label: "samfélagi", value: "samfélagi" },
  { label: "gagnrýninn", value: "gagnrýninn" },
  { label: "samræðum", value: "samræðum" },
  { label: "skólastarfs", value: "skólastarfs" },
  { label: "námsefni", value: "námsefni" },
  { label: "framúrskarandi", value: "framúrskarandi" },
  { label: "námsmati", value: "námsmati" },
  { label: "samfélagsins", value: "samfélagsins" },
  { label: "námsgreinum", value: "námsgreinum" },
  { label: "mikilvægi", value: "mikilvægi" },
  { label: "skólanum", value: "skólanum" },
  { label: "ákvarðanir", value: "ákvarðanir" },
  { label: "verkgreinar", value: "verkgreinar" },
  { label: "megintilgangur", value: "megintilgangur" },
  { label: "viðhorf", value: "viðhorf" },
  { label: "lausnir", value: "lausnir" },
  { label: "kennsluhættir", value: "kennsluhættir" },
  { label: "skólaíþróttum", value: "skólaíþróttum" },
  { label: "námsgreina", value: "námsgreina" },
  { label: "afstöðu", value: "afstöðu" },
  { label: "vinnubrögðum", value: "vinnubrögðum" },
  { label: "einföldum", value: "einföldum" },
  { label: "einföld", value: "einföld" },
  { label: "árangri", value: "árangri" },
  { label: "matsaðferðir", value: "matsaðferðir" },
  { label: "leiðsögn", value: "leiðsögn" },
  { label: "vinnubrögð", value: "vinnubrögð" },
  { label: "skoðanir", value: "skoðanir" },
  { label: "einfaldan", value: "einfaldan" },
  { label: "einfaldar", value: "einfaldar" },
  { label: "grunnskólum", value: "grunnskólum" },
  { label: "samstarf", value: "samstarf" },
  { label: "málfræði", value: "málfræði" },
  { label: "alhliða", value: "alhliða" },
  { label: "umfjöllun", value: "umfjöllun" },
  { label: "skrifað", value: "skrifað" },
  { label: "samskipti", value: "samskipti" },
  { label: "upphafi", value: "upphafi" },
  { label: "frumkvæði", value: "frumkvæði" },
  { label: "upplýsingum", value: "upplýsingum" },
  { label: "fjölbreyttan", value: "fjölbreyttan" },
  { label: "undanþágu", value: "undanþágu" },
  { label: "starfsfólk", value: "starfsfólk" },
  { label: "skyldunámi", value: "skyldunámi" },
  { label: "kennsla", value: "kennsla" },
  { label: "verkgreina", value: "verkgreina" },
  { label: "leikskóla", value: "leikskóla" },
  { label: "kennurum", value: "kennurum" },
  { label: "almennum", value: "almennum" },
  { label: "áhrifum", value: "áhrifum" },
  { label: "nauðsynlegt", value: "nauðsynlegt" },
  { label: "umhverfinu", value: "umhverfinu" },
  { label: "skilningi", value: "skilningi" },
  { label: "tilfinningar", value: "tilfinningar" },
  { label: "sjálfsmynd", value: "sjálfsmynd" },
  { label: "tjáningu", value: "tjáningu" },
  { label: "tengist", value: "tengist" },
  { label: "viðmiðunarstundaskrá", value: "viðmiðunarstundaskrá" },
  { label: "bókmenntir", value: "bókmenntir" },
  { label: "skiptir", value: "skiptir" },
  { label: "grunnþáttum", value: "grunnþáttum" },
  { label: "aðstæðum", value: "aðstæðum" },
  { label: "námssviða", value: "námssviða" },
  { label: "framkvæmd", value: "framkvæmd" },
  { label: "verkfæri", value: "verkfæri" },
  { label: "námsins", value: "námsins" },
  { label: "hlustað", value: "hlustað" },
  { label: "skólastjóri", value: "skólastjóri" },
  { label: "skyldur", value: "skyldur" },
  { label: "verkgreinum", value: "verkgreinum" },
  { label: "áherslur", value: "áherslur" },
  { label: "hverjum", value: "hverjum" },
  { label: "möguleika", value: "möguleika" },
  { label: "styrkja", value: "styrkja" },
  { label: "sjálfum", value: "sjálfum" },
  { label: "stærðfræðileg", value: "stærðfræðileg" },
  { label: "náttúrugreinum", value: "náttúrugreinum" },
  { label: "námssviðum", value: "námssviðum" },
  { label: "tryggja", value: "tryggja" },
  { label: "almennri", value: "almennri" },
  { label: "fjölbreytt", value: "fjölbreytt" },
  { label: "tilgangi", value: "tilgangi" },
  { label: "hliðsjón", value: "hliðsjón" },
  { label: "grunnþættir", value: "grunnþættir" },
  { label: "heimila", value: "heimila" },
  { label: "hugmyndum", value: "hugmyndum" },
  { label: "áherslum", value: "áherslum" },
  { label: "móðurmáli", value: "móðurmáli" },
  { label: "skipulagt", value: "skipulagt" },
  { label: "ályktanir", value: "ályktanir" },
  { label: "kynnast", value: "kynnast" },
  { label: "verkefna", value: "verkefna" },
  { label: "vitnisburður", value: "vitnisburður" },
  { label: "rökrætt", value: "rökrætt" },
  { label: "almenna", value: "almenna" },
  { label: "kennsluaðferðir", value: "kennsluaðferðir" },
  { label: "tæknimennt", value: "tæknimennt" },
  { label: "starfsáætlun", value: "starfsáætlun" },
  { label: "hlustun", value: "hlustun" },
  { label: "íslenskt", value: "íslenskt" },
  { label: "táknmálstexta", value: "táknmálstexta" },
  { label: "einstaklinga", value: "einstaklinga" },
  { label: "einstökum", value: "einstökum" },
  { label: "ungmenna", value: "ungmenna" },
  { label: "mikilvæg", value: "mikilvæg" },
  { label: "samfélag", value: "samfélag" },
  { label: "einungis", value: "einungis" },
  { label: "merkingu", value: "merkingu" },
  { label: "úrvinnslu", value: "úrvinnslu" },
  { label: "hreyfingu", value: "hreyfingu" },
  { label: "daglegt", value: "daglegt" },
  { label: "matsviðmiðin", value: "matsviðmiðin" },
  { label: "notaður", value: "notaður" },
  { label: "framkvæmt", value: "framkvæmt" },
  { label: "sameiginlegum", value: "sameiginlegum" },
  { label: "skólastarf", value: "skólastarf" },
  { label: "grundvelli", value: "grundvelli" },
  { label: "leiklist", value: "leiklist" },
  { label: "starfsemi", value: "starfsemi" },
  { label: "skipulagningu", value: "skipulagningu" },
  { label: "samfélagið", value: "samfélagið" },
  { label: "ábyrgan", value: "ábyrgan" },
  { label: "verkefnum", value: "verkefnum" },
  { label: "leiðbeiningar", value: "leiðbeiningar" },
  { label: "niðurstöðum", value: "niðurstöðum" },
  { label: "móðurmál", value: "móðurmál" },
  { label: "reiknað", value: "reiknað" },
  { label: "samfélagsgreinar", value: "samfélagsgreinar" },
  { label: "eftirfarandi", value: "eftirfarandi" },
  { label: "samhliða", value: "samhliða" },
  { label: "erlendum", value: "erlendum" },
  { label: "námsmats", value: "námsmats" },
  { label: "stuðlar", value: "stuðlar" },
  { label: "leiðarljósi", value: "leiðarljósi" },
  { label: "skólinn", value: "skólinn" },
  { label: "sérstaklega", value: "sérstaklega" },
  { label: "áhersluþáttum", value: "áhersluþáttum" },
  { label: "skólagöngu", value: "skólagöngu" },
  { label: "tungumáli", value: "tungumáli" },
  { label: "skipulega", value: "skipulega" },
  { label: "heimilt", value: "heimilt" },
  { label: "hugtökum", value: "hugtökum" },
  { label: "kennsluhætti", value: "kennsluhætti" },
  { label: "grunnskólalögum", value: "grunnskólalögum" },
  { label: "gagnrýna", value: "gagnrýna" },
  { label: "skólastarfinu", value: "skólastarfinu" },
  { label: "skólastarfið", value: "skólastarfið" },
  { label: "hliðsjónar", value: "hliðsjónar" },
  { label: "skilgreind", value: "skilgreind" },
  { label: "skólabrag", value: "skólabrag" },
  { label: "einstaklingsins", value: "einstaklingsins" },
  { label: "umhverfis", value: "umhverfis" },
  { label: "samfélags", value: "samfélags" },
  { label: "grunnþættirnir", value: "grunnþættirnir" },
  { label: "málnotkun", value: "málnotkun" },
  { label: "miðlalæsi", value: "miðlalæsi" },
  { label: "þessara", value: "þessara" },
  { label: "uppbyggingu", value: "uppbyggingu" },
  { label: "skipuleggja", value: "skipuleggja" },
  { label: "sjálfstætt", value: "sjálfstætt" },
  { label: "upplifun", value: "upplifun" },
  { label: "íþróttum", value: "íþróttum" },
  { label: "nokkrum", value: "nokkrum" },
  { label: "framsetningu", value: "framsetningu" },
  { label: "heimildir", value: "heimildir" },
  { label: "sveitarfélaga", value: "sveitarfélaga" },
  { label: "mannréttindi", value: "mannréttindi" },
  { label: "aðgreiningar", value: "aðgreiningar" },
  { label: "tungumálum", value: "tungumálum" },
  { label: "listgreinar", value: "listgreinar" },
  { label: "heimilisfræði", value: "heimilisfræði" },
  { label: "textílmennt", value: "textílmennt" },
  { label: "árangur", value: "árangur" },
  { label: "siðferði", value: "siðferði" },
  { label: "stuðlað", value: "stuðlað" },
  { label: "forsenda", value: "forsenda" },
  { label: "margvíslegum", value: "margvíslegum" },
  { label: "byggðar", value: "byggðar" },
  { label: "brugðist", value: "brugðist" },
  { label: "skoðunum", value: "skoðunum" },
  { label: "áreiðanleika", value: "áreiðanleika" },
  { label: "rannsaka", value: "rannsaka" },
  { label: "rannsakað", value: "rannsakað" },
  { label: "breytingum", value: "breytingum" },
  { label: "hæfniviðmiða", value: "hæfniviðmiða" },
  { label: "einstakra", value: "einstakra" },
  { label: "starfsfólks", value: "starfsfólks" },
  { label: "tjáning", value: "tjáning" },
  { label: "umburðarlyndi", value: "umburðarlyndi" },
  { label: "viðhorfum", value: "viðhorfum" },
  { label: "samskipta", value: "samskipta" },
  { label: "viðhalda", value: "viðhalda" },
  { label: "tileinka", value: "tileinka" },
  { label: "markvisst", value: "markvisst" },
  { label: "félagsfærni", value: "félagsfærni" },
  { label: "einfalda", value: "einfalda" },
  { label: "rökstutt", value: "rökstutt" },
  { label: "matskvarða", value: "matskvarða" },
  { label: "atriðum", value: "atriðum" },
  { label: "stuðningi", value: "stuðningi" },
  { label: "málefni", value: "málefni" },
  { label: "starfsháttum", value: "starfsháttum" },
  { label: "námsgögn", value: "námsgögn" },
  { label: "skólaráð", value: "skólaráð" },
  { label: "skólastiga", value: "skólastiga" },
  { label: "skólastigum", value: "skólastigum" },
  { label: "tilgang", value: "tilgang" },
  { label: "samráði", value: "samráði" },
  { label: "menningar", value: "menningar" },
  { label: "ungmenni", value: "ungmenni" },
  { label: "einstaklings", value: "einstaklings" },
  { label: "sjálfstæði", value: "sjálfstæði" },
  { label: "mikilvægur", value: "mikilvægur" },
  { label: "ígrundun", value: "ígrundun" },
  { label: "tónlist", value: "tónlist" },
  { label: "bregðast", value: "bregðast" },
  { label: "lífsskilyrði", value: "lífsskilyrði" },
  { label: "nýtingu", value: "nýtingu" },
  { label: "athygli", value: "athygli" },
  { label: "almennt", value: "almennt" },
  { label: "leiðsagnarmat", value: "leiðsagnarmat" },
  { label: "reglulega", value: "reglulega" },
  { label: "áætlanir", value: "áætlanir" },
  { label: "innritun", value: "innritun" },
  { label: "framhaldi", value: "framhaldi" },
  { label: "birtast", value: "birtast" },
  { label: "heimabyggð", value: "heimabyggð" },
  { label: "einkennum", value: "einkennum" },
  { label: "umræðum", value: "umræðum" },
  { label: "unglingastigi", value: "unglingastigi" },
  { label: "kennsluaðferðum", value: "kennsluaðferðum" },
  { label: "meðferð", value: "meðferð" },
  { label: "hæfniviðmiðin", value: "hæfniviðmiðin" },
  { label: "táknmáls", value: "táknmáls" },
  { label: "nánasta", value: "nánasta" },
  { label: "athugunum", value: "athugunum" },
  { label: "hugbúnaðforrit", value: "hugbúnaðforrit" },
  { label: "greinum", value: "greinum" },
  { label: "skólaíþrótta", value: "skólaíþrótta" },
  { label: "samfélagsgreina", value: "samfélagsgreina" },
  { label: "þátttakendur", value: "þátttakendur" },
  { label: "sérstaka", value: "sérstaka" },
  { label: "daglegs", value: "daglegs" },
  { label: "fléttast", value: "fléttast" },
  { label: "framfæri", value: "framfæri" },
  { label: "margvísleg", value: "margvísleg" },
  { label: "forsendum", value: "forsendum" },
  { label: "hæfileika", value: "hæfileika" },
  { label: "vellíðan", value: "vellíðan" },
  { label: "þjálfast", value: "þjálfast" },
  { label: "verklag", value: "verklag" },
  { label: "verkleg", value: "verkleg" },
  { label: "æfingar", value: "æfingar" },
  { label: "athuganir", value: "athuganir" },
  { label: "stendur", value: "stendur" },
  { label: "nýsköpun", value: "nýsköpun" },
  { label: "tileinkað", value: "tileinkað" },
  { label: "uppruna", value: "uppruna" },
  { label: "lýsingu", value: "lýsingu" },
  { label: "rökræðum", value: "rökræðum" },
  { label: "aðgerðum", value: "aðgerðum" },
  { label: "upplýsingatækni", value: "upplýsingatækni" },
  { label: "algengustu", value: "algengustu" },
  { label: "uppsetningu", value: "uppsetningu" },
  { label: "hugbúnað", value: "hugbúnað" },
  { label: "skólastjóra", value: "skólastjóra" },
  { label: "sérfræðiþjónustu", value: "sérfræðiþjónustu" },
  { label: "ákvörðun", value: "ákvörðun" },
  { label: "táknmálstúlks", value: "táknmálstúlks" },
  { label: "sjónlistir", value: "sjónlistir" },
  { label: "skólastarfsins", value: "skólastarfsins" },
  { label: "hjálpartæki", value: "hjálpartæki" },
  { label: "gagnvart", value: "gagnvart" },
  { label: "mannréttindum", value: "mannréttindum" },
  { label: "samspili", value: "samspili" },
  { label: "þróunar", value: "þróunar" },
  { label: "einstaklingur", value: "einstaklingur" },
  { label: "sveitarfélags", value: "sveitarfélags" },
  { label: "forvitni", value: "forvitni" },
  { label: "útskýra", value: "útskýra" },
  { label: "réttindi", value: "réttindi" },
  { label: "ráðuneytið", value: "ráðuneytið" },
  { label: "skriflega", value: "skriflega" },
  { label: "grunnskólinn", value: "grunnskólinn" },
  { label: "sjálfstraust", value: "sjálfstraust" },
  { label: "munnlega", value: "munnlega" },
  { label: "forsendur", value: "forsendur" },
  { label: "námsframvindu", value: "námsframvindu" },
  { label: "tilgreind", value: "tilgreind" },
  { label: "námsgrein", value: "námsgrein" },
  { label: "greinarinnar", value: "greinarinnar" },
  { label: "framsetning", value: "framsetning" },
  { label: "tilraunir", value: "tilraunir" },
  { label: "niðurstöðu", value: "niðurstöðu" },
  { label: "gagnrýni", value: "gagnrýni" },
  { label: "heimilda", value: "heimilda" },
  { label: "matsgögn", value: "matsgögn" },
  { label: "fróðleiks", value: "fróðleiks" },
  { label: "vísinda", value: "vísinda" },
  { label: "íslandi", value: "íslandi" },
  { label: "lausnaleiðir", value: "lausnaleiðir" },
  { label: "grundvöllur", value: "grundvöllur" },
  { label: "grunnskólans", value: "grunnskólans" },
  { label: "náttúrugreinar", value: "náttúrugreinar" },
  { label: "breytingar", value: "breytingar" },
  { label: "félagslegt", value: "félagslegt" },
  { label: "fjölbreytni", value: "fjölbreytni" },
  { label: "tónmennt", value: "tónmennt" },
  { label: "samfélagsgreinum", value: "samfélagsgreinum" },
  { label: "samfellu", value: "samfellu" },
  { label: "lýðræðissamfélagi", value: "lýðræðissamfélagi" },
  { label: "eðlilegt", value: "eðlilegt" },
  { label: "aðgreind", value: "aðgreind" },
  { label: "samþætt", value: "samþætt" },
  { label: "skilgreint", value: "skilgreint" },
  { label: "leitast", value: "leitast" },
  { label: "skipulagi", value: "skipulagi" },
  { label: "innbyrðis", value: "innbyrðis" },
  { label: "lýðræðislegu", value: "lýðræðislegu" },
  { label: "uppeldi", value: "uppeldi" },
  { label: "einstaklingum", value: "einstaklingum" },
  { label: "byggjast", value: "byggjast" },
  { label: "grunnur", value: "grunnur" },
  { label: "nálgast", value: "nálgast" },
  { label: "hugmynd", value: "hugmynd" },
  { label: "ofbeldi", value: "ofbeldi" },
  { label: "jafnréttis", value: "jafnréttis" },
  { label: "verkefnavinnu", value: "verkefnavinnu" },
  { label: "skilningur", value: "skilningur" },
  { label: "kennslustundum", value: "kennslustundum" },
  { label: "jákvæðan", value: "jákvæðan" },
  { label: "hreyfing", value: "hreyfing" },
  { label: "tilfinningum", value: "tilfinningum" },
  { label: "styrkleika", value: "styrkleika" },
  { label: "samvinna", value: "samvinna" },
  { label: "mynstur", value: "mynstur" },
  { label: "æskilegt", value: "æskilegt" },
  { label: "námsbrautum", value: "námsbrautum" },
  { label: "sveitarfélög", value: "sveitarfélög" },
  { label: "árganga", value: "árganga" },
  { label: "umgengni", value: "umgengni" },
  { label: "sjálfstæðan", value: "sjálfstæðan" },
  { label: "sérþarfir", value: "sérþarfir" },
  { label: "sjónarmiðum", value: "sjónarmiðum" },
  { label: "skýringar", value: "skýringar" },
  { label: "megintilgangi", value: "megintilgangi" },
  { label: "skilgreinda", value: "skilgreinda" },
  { label: "brautskráningu", value: "brautskráningu" },
  { label: "standast", value: "standast" },
  { label: "hæfniviðmiðunum", value: "hæfniviðmiðunum" },
  { label: "fjölbreytta", value: "fjölbreytta" },
  { label: "endurgjöf", value: "endurgjöf" },
  { label: "lokamat", value: "lokamat" },
  { label: "gleggstar", value: "gleggstar" },
  { label: "undirbúa", value: "undirbúa" },
  { label: "leikjum", value: "leikjum" },
  { label: "íslenskar", value: "íslenskar" },
  { label: "stafsetningu", value: "stafsetningu" },
  { label: "sjálfsmat", value: "sjálfsmat" },
  { label: "líkamans", value: "líkamans" },
  { label: "útreikninga", value: "útreikninga" },
  { label: "þessari", value: "þessari" },
  { label: "leiðarljós", value: "leiðarljós" },
  { label: "skólaíþróttir", value: "skólaíþróttir" },
  { label: "námshæfni", value: "námshæfni" },
  { label: "náttúrugreina", value: "náttúrugreina" },
  { label: "grunnþætti", value: "grunnþætti" },
  { label: "útfærslu", value: "útfærslu" },
  { label: "starfsfólki", value: "starfsfólki" },
  { label: "rannsóknir", value: "rannsóknir" },
  { label: "birtist", value: "birtist" },
  { label: "fræðslu", value: "fræðslu" },
  { label: "þjónustu", value: "þjónustu" },
  { label: "íslands", value: "íslands" },
  { label: "einkenni", value: "einkenni" },
  { label: "hreyfingar", value: "hreyfingar" },
  { label: "styrkleikum", value: "styrkleikum" },
  { label: "grundvallar", value: "grundvallar" },
  { label: "framfarir", value: "framfarir" },
  { label: "fylgjast", value: "fylgjast" },
  { label: "undirstaða", value: "undirstaða" },
  { label: "svigrúm", value: "svigrúm" },
  { label: "nauðsynleg", value: "nauðsynleg" },
  { label: "vettvangur", value: "vettvangur" },
  { label: "fjölskyldu", value: "fjölskyldu" },
  { label: "skilgreina", value: "skilgreina" },
  { label: "nemandanum", value: "nemandanum" },
  { label: "hagnýtt", value: "hagnýtt" },
  { label: "barnsins", value: "barnsins" },
  { label: "stuðnings", value: "stuðnings" },
  { label: "valgreina", value: "valgreina" },
  { label: "tungumálið", value: "tungumálið" },
  { label: "tímabil", value: "tímabil" },
  { label: "röksemdafærslu", value: "röksemdafærslu" },
  { label: "auðvelda", value: "auðvelda" },
  { label: "framvindu", value: "framvindu" },
  { label: "kvarðinn", value: "kvarðinn" },
  { label: "sæmilegri", value: "sæmilegri" },
  { label: "uppfyllir", value: "uppfyllir" },
  { label: "matsniðurstöður", value: "matsniðurstöður" },
  { label: "traustum", value: "traustum" },
  { label: "nákvæma", value: "nákvæma" },
  { label: "vettvangsathugun", value: "vettvangsathugun" },
  { label: "einkunnagjöf", value: "einkunnagjöf" },
  { label: "vitnisburði", value: "vitnisburði" },
  { label: "réttmæti", value: "réttmæti" },
  { label: "matsþætti", value: "matsþætti" },
  { label: "vakandi", value: "vakandi" },
  { label: "sköpunar", value: "sköpunar" },
  { label: "atburði", value: "atburði" },
  { label: "tónlistar", value: "tónlistar" },
  { label: "rafrænt", value: "rafrænt" },
  { label: "aðalatriði", value: "aðalatriði" },
  { label: "orðabækur", value: "orðabækur" },
  { label: "jafningjamat", value: "jafningjamat" },
  { label: "heilsurækt", value: "heilsurækt" },
  { label: "lífsviðhorfa", value: "lífsviðhorfa" },
  { label: "tilgátur", value: "tilgátur" },
  { label: "teikningar", value: "teikningar" },
  { label: "menningarmálaráðherra", value: "menningarmálaráðherra" },
  { label: "flestum", value: "flestum" },
  { label: "starfsráðgjöf", value: "starfsráðgjöf" },
  { label: "framkomu", value: "framkomu" },
  { label: "könnunarpróf", value: "könnunarpróf" },
  { label: "sameiginleg", value: "sameiginleg" },
  { label: "félagslegum", value: "félagslegum" },
  { label: "menntastefnu", value: "menntastefnu" },
  { label: "skólastefnu", value: "skólastefnu" },
  { label: "skólakerfinu", value: "skólakerfinu" },
  { label: "þjóðarinnar", value: "þjóðarinnar" },
  { label: "inntaki", value: "inntaki" },
  { label: "aukinni", value: "aukinni" },
  { label: "heilbrigða", value: "heilbrigða" },
  { label: "hæfileikum", value: "hæfileikum" },
  { label: "gagnrýninni", value: "gagnrýninni" },
  { label: "kunnáttu", value: "kunnáttu" },
  { label: "náttúruna", value: "náttúruna" },
  { label: "margbreytileika", value: "margbreytileika" },
  { label: "álitamál", value: "álitamál" },
  { label: "aðgerða", value: "aðgerða" },
  { label: "samábyrgð", value: "samábyrgð" },
  { label: "virðing", value: "virðing" },
  { label: "skilnings", value: "skilnings" },
  { label: "viðfangsefna", value: "viðfangsefna" },
  { label: "sköpunarmátt", value: "sköpunarmátt" },
  { label: "ábyrgur", value: "ábyrgur" },
  { label: "ígrunda", value: "ígrunda" },
  { label: "vitneskju", value: "vitneskju" },
  { label: "ástundun", value: "ástundun" },
  { label: "lýðræðisþjóðfélagi", value: "lýðræðisþjóðfélagi" },
  { label: "sérstöðu", value: "sérstöðu" },
  { label: "umhyggju", value: "umhyggju" },
  { label: "markmiði", value: "markmiði" },
  { label: "sveitarfélögum", value: "sveitarfélögum" },
  { label: "skólanefnd", value: "skólanefnd" },
  { label: "hugsanir", value: "hugsanir" },
  { label: "verklagi", value: "verklagi" },
  { label: "nærumhverfi", value: "nærumhverfi" },
  { label: "markvissan", value: "markvissan" },
  { label: "myndefni", value: "myndefni" },
  { label: "náttúrufræði", value: "náttúrufræði" },
  { label: "ástæður", value: "ástæður" },
  { label: "ákveðnum", value: "ákveðnum" },
  { label: "skólasundi", value: "skólasundi" },
  { label: "tjáningar", value: "tjáningar" },
  { label: "íslensks", value: "íslensks" },
  { label: "framsögn", value: "framsögn" },
  { label: "framburð", value: "framburð" },
  { label: "frásögnum", value: "frásögnum" },
  { label: "samræður", value: "samræður" },
  { label: "bókmennta", value: "bókmennta" },
  { label: "tillitssemi", value: "tillitssemi" },
  { label: "málsgreinar", value: "málsgreinar" },
  { label: "myndrænt", value: "myndrænt" },
  { label: "atburðum", value: "atburðum" },
  { label: "kennarinn", value: "kennarinn" },
  { label: "handverk", value: "handverk" },
  { label: "vinnuferli", value: "vinnuferli" },
  { label: "viðbótar", value: "viðbótar" },
  { label: "myndrit", value: "myndrit" },
  { label: "auglýsing", value: "auglýsing" },
  { label: "sveitarfélagsins", value: "sveitarfélagsins" },
  { label: "grunnskólanámi", value: "grunnskólanámi" },
  { label: "námskrá", value: "námskrá" },
  { label: "fyrirkomulag", value: "fyrirkomulag" },
  { label: "eftirlit", value: "eftirlit" },
  { label: "skólabragur", value: "skólabragur" },
  { label: "undanþága", value: "undanþága" },
  { label: "tungumála", value: "tungumála" },
  { label: "listgreina", value: "listgreina" },
  { label: "sviðslistir", value: "sviðslistir" },
  { label: "starfshætti", value: "starfshætti" },
  { label: "gagnrýnir", value: "gagnrýnir" },
  { label: "starfsmönnum", value: "starfsmönnum" },
  { label: "ungmennum", value: "ungmennum" },
  { label: "samhæfingu", value: "samhæfingu" },
  { label: "skólastigi", value: "skólastigi" },
  { label: "námsumhverfi", value: "námsumhverfi" },
  { label: "lykilhlutverki", value: "lykilhlutverki" },
  { label: "lífshætti", value: "lífshætti" },
  { label: "samfélagslegum", value: "samfélagslegum" },
  { label: "tveggja", value: "tveggja" },
  { label: "hugmynda", value: "hugmynda" },
  { label: "endurspegla", value: "endurspegla" },
  { label: "víðsýni", value: "víðsýni" },
  { label: "einelti", value: "einelti" },
  { label: "einstaklingar", value: "einstaklingar" },
  { label: "þeirrar", value: "þeirrar" },
  { label: "notagildi", value: "notagildi" },
  { label: "umhverfið", value: "umhverfið" },
  { label: "náttúrunni", value: "náttúrunni" },
  { label: "lífsgæði", value: "lífsgæði" },
  { label: "heiminum", value: "heiminum" },
  { label: "meðvitaður", value: "meðvitaður" },
  { label: "lýðræðislegum", value: "lýðræðislegum" },
  { label: "manngildi", value: "manngildi" },
  { label: "lífsleikni", value: "lífsleikni" },
  { label: "heimili", value: "heimili" },
  { label: "menning", value: "menning" },
  { label: "atvinnulífi", value: "atvinnulífi" },
  { label: "aðgengi", value: "aðgengi" },
  { label: "líkamlegri", value: "líkamlegri" },
  { label: "hreinlæti", value: "hreinlæti" },
  { label: "framkvæma", value: "framkvæma" },
  { label: "fyrirbæri", value: "fyrirbæri" },
  { label: "gagnrýnin", value: "gagnrýnin" },
  { label: "krefjast", value: "krefjast" },
  { label: "heimilin", value: "heimilin" },
  { label: "órjúfanlegur", value: "órjúfanlegur" },
  { label: "leiðbeinandi", value: "leiðbeinandi" },
  { label: "stuttar", value: "stuttar" },
  { label: "yfirsýn", value: "yfirsýn" },
  { label: "ráðuneytisins", value: "ráðuneytisins" },
  { label: "grunnskólalaga", value: "grunnskólalaga" },
  { label: "grunnskólar", value: "grunnskólar" },
  { label: "verklegt", value: "verklegt" },
  { label: "vinnulag", value: "vinnulag" },
  { label: "samskiptatækni", value: "samskiptatækni" },
  { label: "margvíslegar", value: "margvíslegar" },
  { label: "fræðileg", value: "fræðileg" },
  { label: "margvíslega", value: "margvíslega" },
  { label: "sjónarmiða", value: "sjónarmiða" },
  { label: "tilefni", value: "tilefni" },
  { label: "kennslan", value: "kennslan" },
  { label: "jákvæðum", value: "jákvæðum" },
  { label: "trúarbragða", value: "trúarbragða" },
  { label: "skipulegum", value: "skipulegum" },
  { label: "erlenda", value: "erlenda" },
  { label: "skilgreindur", value: "skilgreindur" },
  { label: "áheyrilega", value: "áheyrilega" },
  { label: "skólann", value: "skólann" },
  { label: "athafna", value: "athafna" },
  { label: "viðbrögð", value: "viðbrögð" },
  { label: "heimild", value: "heimild" },
  { label: "skiptast", value: "skiptast" },
  { label: "námsgreinin", value: "námsgreinin" },
  { label: "miðlunar", value: "miðlunar" },
  { label: "gagnrýnum", value: "gagnrýnum" },
  { label: "bókmenntum", value: "bókmenntum" },
  { label: "íslenskum", value: "íslenskum" },
  { label: "hrynjandi", value: "hrynjandi" },
  { label: "algengum", value: "algengum" },
  { label: "ritvinnslu", value: "ritvinnslu" },
  { label: "frásagnir", value: "frásagnir" },
  { label: "skráningu", value: "skráningu" },
  { label: "tungumálanám", value: "tungumálanám" },
  { label: "kennslunnar", value: "kennslunnar" },
  { label: "gátlista", value: "gátlista" },
  { label: "handverks", value: "handverks" },
  { label: "líkamsvitund", value: "líkamsvitund" },
  { label: "matreiðslu", value: "matreiðslu" },
  { label: "heimildum", value: "heimildum" },
  { label: "sundiðkun", value: "sundiðkun" },
  { label: "samræðu", value: "samræðu" },
  { label: "tölvutækni", value: "tölvutækni" },
  { label: "reikniaðgerðanna", value: "reikniaðgerðanna" },
  { label: "rúmfræði", value: "rúmfræði" },
  { label: "mælikvarða", value: "mælikvarða" },
  { label: "hnitakerfi", value: "hnitakerfi" },
  { label: "sjálfsmats", value: "sjálfsmats" },
  { label: "útskrift", value: "útskrift" },
  { label: "fagmennska", value: "fagmennska" },
  { label: "skólaþróun", value: "skólaþróun" },
  { label: "upplýsingamiðlun", value: "upplýsingamiðlun" },
  { label: "reglugerðir", value: "reglugerðir" },
  { label: "reglugerða", value: "reglugerða" },
  { label: "markvissum", value: "markvissum" },
  { label: "styrkir", value: "styrkir" },
  { label: "stofnanir", value: "stofnanir" },
  { label: "margvíslegu", value: "margvíslegu" },
  { label: "fyrirmæli", value: "fyrirmæli" },
  { label: "sveigjanleika", value: "sveigjanleika" },
  { label: "sérkenni", value: "sérkenni" },
  { label: "störfum", value: "störfum" },
  { label: "skólanna", value: "skólanna" },
  { label: "starfshættir", value: "starfshættir" },
  { label: "markviss", value: "markviss" },
  { label: "leiðbeina", value: "leiðbeina" },
  { label: "tileinki", value: "tileinki" },
  { label: "jafnvel", value: "jafnvel" },
  { label: "samfélagsleg", value: "samfélagsleg" },
  { label: "fjölbreyttu", value: "fjölbreyttu" },
  { label: "líkamlega", value: "líkamlega" },
  { label: "samspil", value: "samspil" },
  { label: "réttlæti", value: "réttlæti" },
  { label: "jafnvægi", value: "jafnvægi" },
  { label: "rannsóknum", value: "rannsóknum" },
  { label: "ritunar", value: "ritunar" },
  { label: "beinist", value: "beinist" },
  { label: "hugtökunum", value: "hugtökunum" },
  { label: "sjálfbærrar", value: "sjálfbærrar" },
  { label: "siðferðilegra", value: "siðferðilegra" },
  { label: "kynhneigð", value: "kynhneigð" },
  { label: "félagslegri", value: "félagslegri" },
  { label: "jákvæða", value: "jákvæða" },
  { label: "hagnýtingu", value: "hagnýtingu" },
  { label: "tilteknum", value: "tilteknum" },
  { label: "framförum", value: "framförum" },
  { label: "skrifleg", value: "skrifleg" },
  { label: "námsörðugleika", value: "námsörðugleika" },
  { label: "skriflegum", value: "skriflegum" },
  { label: "mikilvægu", value: "mikilvægu" },
  { label: "heimakennslu", value: "heimakennslu" },
  { label: "þjálfist", value: "þjálfist" },
  { label: "kynningu", value: "kynningu" },
  { label: "siðferðilegum", value: "siðferðilegum" },
  { label: "aðferða", value: "aðferða" },
  { label: "greiningu", value: "greiningu" },
  { label: "trúarbragðafræði", value: "trúarbragðafræði" },
  { label: "námsaðferðir", value: "námsaðferðir" },
  { label: "hlutdeild", value: "hlutdeild" },
  { label: "stuðning", value: "stuðning" },
  { label: "byggingu", value: "byggingu" },
  { label: "siðfræði", value: "siðfræði" },
  { label: "félagslífi", value: "félagslífi" },
  { label: "samþættingu", value: "samþættingu" },
  { label: "heildartími", value: "heildartími" },
  { label: "íþróttir", value: "íþróttir" },
  { label: "stundaskrá", value: "stundaskrá" },
  { label: "tengingu", value: "tengingu" },
  { label: "sambandi", value: "sambandi" },
  { label: "sameiginlegt", value: "sameiginlegt" },
  { label: "aðlögun", value: "aðlögun" },
  { label: "tilraunum", value: "tilraunum" },
  { label: "unglinga", value: "unglinga" },
  { label: "ákveðnu", value: "ákveðnu" },
  { label: "heimilum", value: "heimilum" },
  { label: "nemandann", value: "nemandann" },
  { label: "gagnrýnni", value: "gagnrýnni" },
  { label: "úrlausn", value: "úrlausn" },
  { label: "skapast", value: "skapast" },
  { label: "greinagóðan", value: "greinagóðan" },
  { label: "sérstök", value: "sérstök" },
  { label: "kunnátta", value: "kunnátta" },
  { label: "framburði", value: "framburði" },
  { label: "lesanda", value: "lesanda" },
  { label: "rafrænu", value: "rafrænu" },
  { label: "lesefni", value: "lesefni" },
  { label: "skyldleika", value: "skyldleika" },
  { label: "flokkum", value: "flokkum" },
  { label: "myndefnis", value: "myndefnis" },
  { label: "leikins", value: "leikins" },
  { label: "frásögn", value: "frásögn" },
  { label: "efnisgreinar", value: "efnisgreinar" },
  { label: "bakgrunn", value: "bakgrunn" },
  { label: "lifandi", value: "lifandi" },
  { label: "námssviðinu", value: "námssviðinu" },
  { label: "markaður", value: "markaður" },
  { label: "einföldu", value: "einföldu" },
  { label: "reynsluheim", value: "reynsluheim" },
  { label: "námsmatið", value: "námsmatið" },
  { label: "fagurfræði", value: "fagurfræði" },
  { label: "verkfærni", value: "verkfærni" },
  { label: "jafningja", value: "jafningja" },
  { label: "myndmennt", value: "myndmennt" },
  { label: "myndlistar", value: "myndlistar" },
  { label: "stærðir", value: "stærðir" },
  { label: "tengdum", value: "tengdum" },
  { label: "lífvera", value: "lífvera" },
  { label: "lífssýn", value: "lífssýn" },
  { label: "hitastig", value: "hitastig" },
  { label: "útivist", value: "útivist" },
  { label: "siðferðileg", value: "siðferðileg" },
  { label: "nærsamfélaginu", value: "nærsamfélaginu" },
  { label: "veruleikann", value: "veruleikann" },
  { label: "reikning", value: "reikning" },
  { label: "stærðfræðitexta", value: "stærðfræðitexta" },
  { label: "rúmfræðinnar", value: "rúmfræðinnar" },
  { label: "tölulegra", value: "tölulegra" },
  { label: "gildistöku", value: "gildistöku" },
  { label: "staðfestir", value: "staðfestir" },
  { label: "fylgiskjal", value: "fylgiskjal" },
  { label: "samræmt", value: "samræmt" },
  { label: "menningarmálaráðuneytis", value: "menningarmálaráðuneytis" },
  { label: "skiptist", value: "skiptist" },
  { label: "einstakar", value: "einstakar" },
  { label: "megintilgang", value: "megintilgang" },
  { label: "greinasviðum", value: "greinasviðum" },
  { label: "hæfniþrep", value: "hæfniþrep" },
  { label: "samræmdra", value: "samræmdra" },
  { label: "umsjónarkennara", value: "umsjónarkennara" },
  { label: "félagsþroska", value: "félagsþroska" },
  { label: "hlutaðeigandi", value: "hlutaðeigandi" },
  { label: "bernsku", value: "bernsku" },
  { label: "sérfræðiþjónusta", value: "sérfræðiþjónusta" },
  { label: "valgreinar", value: "valgreinar" },
  { label: "stöðluð", value: "stöðluð" },
  { label: "foreldrafélög", value: "foreldrafélög" },
  { label: "tæknimenntar", value: "tæknimenntar" },
  { label: "yfirlit", value: "yfirlit" },
  { label: "brjósti", value: "brjósti" },
  { label: "ákveður", value: "ákveður" },
  { label: "heildarsýn", value: "heildarsýn" },
  { label: "menningarmálaráðuneytið", value: "menningarmálaráðuneytið" },
  { label: "úttektir", value: "úttektir" },
  { label: "skólastigs", value: "skólastigs" },
  { label: "ráðgjöf", value: "ráðgjöf" },
  { label: "skólastjórnendur", value: "skólastjórnendur" },
  { label: "áskoranir", value: "áskoranir" },
  { label: "þróunarstarfi", value: "þróunarstarfi" },
  { label: "heildstæðri", value: "heildstæðri" },
  { label: "atvikum", value: "atvikum" },
  { label: "námsþátta", value: "námsþátta" },
  { label: "merkingar", value: "merkingar" },
  { label: "hugtakið", value: "hugtakið" },
  { label: "tiltekinni", value: "tiltekinni" },
  { label: "persónulegan", value: "persónulegan" },
  { label: "þýðingu", value: "þýðingu" },
  { label: "umhverfisvernd", value: "umhverfisvernd" },
  { label: "fjölbreytileika", value: "fjölbreytileika" },
  { label: "samfélaga", value: "samfélaga" },
  { label: "auðlinda", value: "auðlinda" },
  { label: "lýðræðis", value: "lýðræðis" },
  { label: "gildismat", value: "gildismat" },
  { label: "samfélög", value: "samfélög" },
  { label: "ábyrgðar", value: "ábyrgðar" },
  { label: "íslenskri", value: "íslenskri" },
  { label: "þroskast", value: "þroskast" },
  { label: "menningarheimum", value: "menningarheimum" },
  { label: "dagsins", value: "dagsins" },
  { label: "kynheilbrigði", value: "kynheilbrigði" },
  { label: "lífshátta", value: "lífshátta" },
  { label: "daglega", value: "daglega" },
  { label: "áhugamál", value: "áhugamál" },
  { label: "fyrirmyndir", value: "fyrirmyndir" },
  { label: "listsköpun", value: "listsköpun" },
  { label: "framhaldsskólastigi", value: "framhaldsskólastigi" },
  { label: "meðvitaðir", value: "meðvitaðir" },
  { label: "framfara", value: "framfara" },
  { label: "skilyrði", value: "skilyrði" },
  { label: "áreiðanlegt", value: "áreiðanlegt" },
  { label: "afmarkaðar", value: "afmarkaðar" },
  { label: "einstaklingsverkefni", value: "einstaklingsverkefni" },
  { label: "hópverkefni", value: "hópverkefni" },
  { label: "rafrænum", value: "rafrænum" },
  { label: "sértækra", value: "sértækra" },
  { label: "almennu", value: "almennu" },
  { label: "umbótum", value: "umbótum" },
  { label: "sjálfstæða", value: "sjálfstæða" },
  { label: "samskiptahæfni", value: "samskiptahæfni" },
  { label: "sérfræðinga", value: "sérfræðinga" },
  { label: "stundar", value: "stundar" },
  { label: "íslenskrar", value: "íslenskrar" },
  { label: "laganna", value: "laganna" },
  { label: "sveitarfélaginu", value: "sveitarfélaginu" },
  { label: "skólasamfélagið", value: "skólasamfélagið" },
  { label: "grunnskólastigi", value: "grunnskólastigi" },
  { label: "sjálfan", value: "sjálfan" },
  { label: "útfærsla", value: "útfærsla" },
  { label: "borgaravitund", value: "borgaravitund" },
  { label: "einkalífi", value: "einkalífi" },
  { label: "vettvang", value: "vettvang" },
  { label: "markvissri", value: "markvissri" },
  { label: "hagnýta", value: "hagnýta" },
  { label: "norðurlandamáli", value: "norðurlandamáli" },
  { label: "viðmiðin", value: "viðmiðin" },
  { label: "umgangast", value: "umgangast" },
  { label: "jákvætt", value: "jákvætt" },
  { label: "kennari", value: "kennari" },
  { label: "undirbúningur", value: "undirbúningur" },
  { label: "varðandi", value: "varðandi" },
  { label: "kennarans", value: "kennarans" },
  { label: "starfsanda", value: "starfsanda" },
  { label: "sjálfsaga", value: "sjálfsaga" },
  { label: "veruleikanum", value: "veruleikanum" },
  { label: "tímabundna", value: "tímabundna" },
  { label: "notaðar", value: "notaðar" },
  { label: "áhugamálum", value: "áhugamálum" },
  { label: "mínútur", value: "mínútur" },
  { label: "bundinn", value: "bundinn" },
  { label: "einkunnina", value: "einkunnina" },
  { label: "frammistöðu", value: "frammistöðu" },
  { label: "verkefnin", value: "verkefnin" },
  { label: "breyttar", value: "breyttar" },
  { label: "öryggis", value: "öryggis" },
  { label: "fyrirmælum", value: "fyrirmælum" },
  { label: "almennan", value: "almennan" },
  { label: "skipulegan", value: "skipulegan" },
  { label: "margskonar", value: "margskonar" },
  { label: "heyrnarlausra", value: "heyrnarlausra" },
  { label: "máluppeldi", value: "máluppeldi" },
  { label: "sjálfsagt", value: "sjálfsagt" },
  { label: "lestrar", value: "lestrar" },
  { label: "norrænu", value: "norrænu" },
  { label: "málanna", value: "málanna" },
  { label: "gagnabrunnum", value: "gagnabrunnum" },
  { label: "kennslunni", value: "kennslunni" },
  { label: "talhraða", value: "talhraða" },
  { label: "leikrænnar", value: "leikrænnar" },
  { label: "endursagt", value: "endursagt" },
  { label: "skemmtunar", value: "skemmtunar" },
  { label: "hjálpargögn", value: "hjálpargögn" },
  { label: "efnisatriðum", value: "efnisatriðum" },
  { label: "tungumálsins", value: "tungumálsins" },
  { label: "orðflokka", value: "orðflokka" },
  { label: "táknmálið", value: "táknmálið" },
  { label: "einhverju", value: "einhverju" },
  { label: "uppbyggjandi", value: "uppbyggjandi" },
  { label: "íslendingar", value: "íslendingar" },
  { label: "samkennd", value: "samkennd" },
  { label: "myndmiðlum", value: "myndmiðlum" },
  { label: "leiðbeiningum", value: "leiðbeiningum" },
  { label: "undirbúið", value: "undirbúið" },
  { label: "nákvæmni", value: "nákvæmni" },
  { label: "spurningum", value: "spurningum" },
  { label: "daglegum", value: "daglegum" },
  { label: "umorðað", value: "umorðað" },
  { label: "kennsluna", value: "kennsluna" },
  { label: "kynningar", value: "kynningar" },
  { label: "greinar", value: "greinar" },
  { label: "afleiðingar", value: "afleiðingar" },
  { label: "sjónarhorn", value: "sjónarhorn" },
  { label: "lausnum", value: "lausnum" },
  { label: "upplifa", value: "upplifa" },
  { label: "vandamál", value: "vandamál" },
  { label: "hönnunar", value: "hönnunar" },
  { label: "hljóðfæri", value: "hljóðfæri" },
  { label: "lífsháttum", value: "lífsháttum" },
  { label: "næringarfræði", value: "næringarfræði" },
  { label: "heimilishald", value: "heimilishald" },
  { label: "matvæla", value: "matvæla" },
  { label: "eiginleika", value: "eiginleika" },
  { label: "textílvinnu", value: "textílvinnu" },
  { label: "frammistöðumat", value: "frammistöðumat" },
  { label: "munnlegar", value: "munnlegar" },
  { label: "nýsköpunar", value: "nýsköpunar" },
  { label: "heilsuræktar", value: "heilsuræktar" },
  { label: "mælingar", value: "mælingar" },
  { label: "talningar", value: "talningar" },
  { label: "spurningar", value: "spurningar" },
  { label: "lífsviðhorf", value: "lífsviðhorf" },
  { label: "stærðfræðilegra", value: "stærðfræðilegra" },
  { label: "hversdagsleg", value: "hversdagsleg" },
  { label: "rauntölur", value: "rauntölur" },
  { label: "mynstrum", value: "mynstrum" },
  { label: "teiknað", value: "teiknað" },
  { label: "undirstöðuhugtök", value: "undirstöðuhugtök" },
  { label: "einslögun", value: "einslögun" },
  { label: "rúmfræðileg", value: "rúmfræðileg" },
  { label: "ritsmíða", value: "ritsmíða" },
  { label: "áorðnum", value: "áorðnum" },
  { label: "stjórnendur", value: "stjórnendur" },
  { label: "viðmiðun", value: "viðmiðun" },
  { label: "menningarmálaráðuneytinu", value: "menningarmálaráðuneytinu" },
  { label: "magnúsdóttir", value: "magnúsdóttir" },
  { label: "útgáfud", value: "útgáfud" },
  { label: "september", value: "september" },
  { label: "sértæka", value: "sértæka" },
  { label: "brautskráning", value: "brautskráning" },
  { label: "könnunarprófa", value: "könnunarprófa" },
  { label: "útskrifast", value: "útskrifast" },
];
