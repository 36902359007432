import React from "react";
import { GridColumn } from "@island.is/core";

import * as styles from "./FooterLogo.treat";
import { useMediaQuery } from "react-responsive";

interface FooterLogoProps {
  Data: any;
}

const FooterLogo = ({ Data }: FooterLogoProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <GridColumn
      span={isSmall ? "12/12" : "4/12"}
      paddingBottom={2}
      className={styles.root}
    >
      <a href={Data.primary.link[0].text} target="_blank" rel="noreferrer">
        <img
          src={Data.primary.img.url}
          alt={Data.primary.img.alt}
          width={isSmall ? "70%" : "100%"}
        />
      </a>
    </GridColumn>
  );
};

export default FooterLogo;
