import React from "react"
import { SearchBar } from "components/SearchBar"

export default function Nav() {
  return (
    <>
      <div className="blog-navbar">
        <div>
          <a href="/">Forsíða</a>
          <a href="/adalnamskra-grunnskola">Grunnskóli</a>
          <a href="/adalnamskra-grunnskola">Leikskóli</a>
          <a href="/endurskodun-greinasvida">Endurskoðun</a>
        </div>
        <SearchBar />
      </div>
    </>
  )
}
