import React from "react";
import * as styles from "./VideoIframe.treat";

export interface VideoIframeProps {
  src: string;
  title?: string;
}

function isValidUrl(string: string) {
  // eslint-disable-next-line no-useless-escape
  const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return matchPattern.test(string);
}

export const VideoIframe = ({ src, title = "video" }: VideoIframeProps) => (
  <div className={styles.container}>
    <iframe
      title={title}
      scrolling="no"
      width="560"
      height="315"
      src={src}
      frameBorder="0"
      allowFullScreen
      className={styles.iframe}
      style={isValidUrl(src) ? {} : { display: "none" }}
    />
  </div>
);
