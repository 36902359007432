import {
  Box,
  GridContainer,
  GridRow,
  GridColumn,
  TopicCard,
} from "@island.is/core"
import { useHistory } from "react-router-dom"
import { RichText } from "prismic-reactjs"
import { useMediaQuery } from "react-responsive"

import { sortLang } from "../../../../utils"
// import { LangBtn } from "..";

interface NavProps {
  Data: any
  LeikskolaProps: any
  lang: "en-gb" | "en-us"
}

const backup = [
  {
    href: "/adalnamskra-grunnskola/auglysingar",
    index: 1,
    title: "Gildistaka og breytingar á aðalnámskrá",
    type: "Formali",
  },
  {
    href: "/adalnamskra-grunnskola/kafli-1",
    index: 41,
    title: "Kafli 1 - Hlutverk aðalnámskrár",
    type: "Almennur-Hluti",
  },
  {
    href: "/adalnamskra-grunnskola/kafli-17",
    index: 1,
    title: "Kafli 17 - Greinasvið",
    type: "Almennur-Hluti",
  },
]

const Nav = ({
  Data = backup,
  LeikskolaProps = {
    titleone: "",
    titletwo: "",
    titlethree: "",
  },
  lang,
}: NavProps) => {
  if (Data.length === 0) {
    Data = backup
  }
  var [AlmennurHluti, Greinasvid, Formali] = sortLang(Data, lang)
  let history = useHistory()
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" })
  return (
    <>
      {console.log(Data)}
      <Box>
        <GridContainer>
          {/* <LangBtn lang={lang}/> */}
          <GridRow>
            {Formali.map((data: any, index: number) => (
              <GridColumn span={isSmall ? "12/12" : "4/12"}>
                <Box paddingBottom={0} paddingTop={2}>
                  <Box key={index} paddingBottom={0} className="HoverContainer">
                    <Box className="HoverItem customprint">
                      <TopicCard
                        onClick={() => history.push(data.href)}
                        colorScheme="red"
                      >
                        {data.title}
                      </TopicCard>
                    </Box>
                  </Box>
                </Box>
              </GridColumn>
            ))}
            {AlmennurHluti.map((data: any, index: number) => (
              <GridColumn span={isSmall ? "12/12" : "4/12"}>
                <Box paddingBottom={0} paddingTop={2}>
                  <Box key={index} paddingBottom={0} className="HoverContainer">
                    <Box className="HoverItem customprint">
                      <TopicCard
                        onClick={() => history.push(data.href)}
                        colorScheme="red"
                      >
                        {data.title}
                      </TopicCard>
                    </Box>
                  </Box>
                </Box>
              </GridColumn>
            ))}
            {Greinasvid.map((data: any, index: number) => (
              <GridColumn span={isSmall ? "12/12" : "4/12"}>
                <Box paddingBottom={0} paddingTop={2}>
                  <Box key={index} paddingBottom={0} className="HoverContainer">
                    <Box className="HoverItem customprint">
                      <TopicCard
                        onClick={() => history.push(data.href)}
                        colorScheme="red"
                      >
                        {data.title}
                      </TopicCard>
                    </Box>
                  </Box>
                </Box>
              </GridColumn>
            ))}
          </GridRow>
        </GridContainer>
      </Box>
    </>
  )
}

export default Nav
