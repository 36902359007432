import React from "react";
import { GridColumn, Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";
import { useMediaQuery } from "react-responsive";

interface FooterProps {
  /*
  Data in Prismic format.
  */
  Data: any;
}

const Footer = ({ Data }: FooterProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <GridColumn span={isSmall ? "12/12" : "4/12"} paddingBottom={2}>
      <RichText render={Data.primary.title} />
      {Data.items.map((data: any, index: number) => (
        <Box key={index}>
          <a href={data.link.url} style={{ color: "black" }}>
            <RichText render={data.text} />
          </a>
        </Box>
      ))}
    </GridColumn>
  );
};

export default Footer;
