/*
This is a htmlSerilizer from Prismic.
https://prismic.io/docs/technologies/html-serializer-reactjs

We add bottom padding to RichText.
*/

import React from "react";
import { Elements } from "prismic-reactjs";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

export const paddingSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  var props = {};
  switch (type) {
    case Elements.paragraph:
      props = { style: { paddingBottom: "5px" } };
      return React.createElement("p", propsWithUniqueKey(props, key), children);

    default:
      return null;
  }
};

export default paddingSerializer;
