import React from "react";
import { Box } from "../Box/Box";
import { FocusableBox } from "../FocusableBox/FocusableBox";
import { Inline } from "../Inline/Inline";
import { Tag } from "../Tag/Tag";
import { Text, TextProps } from "../Text/Text";

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// eslint-disable-next-line
type Tag = {
  label: string;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
};

type CategoryCardProps = {
  image?: string;
  imageAlt?: string;
  heading: string;
  small?: boolean;
  text: string;
  tags?: Tag[];
  href?: string;
  colorScheme?: "blue" | "red" | "seagreen";
  truncateHeading?: TextProps["truncate"];
};

const colorSchemes = {
  blue: {
    textColor: "blue400",
    borderColor: "purple200",
    tagVariant: "blue",
  },
  seagreen: {
    textColor: "black",
    borderColor: "seagreen300",
    tagVariant: "blue",
  },
  red: {
    textColor: "red600",
    borderColor: "red200",
    tagVariant: "red",
  },
} as const;

export const CategoryCard = ({
  heading,
  image,
  imageAlt = "image",
  small = false,
  text,
  href = "/",
  tags = [],
  colorScheme = "blue",
  truncateHeading = false,
}: CategoryCardProps) => {
  const hasTags = Array.isArray(tags) && tags.length > 0;
  const { borderColor, textColor, tagVariant } = colorSchemes[colorScheme];

  return (
    <Box>
      {!image && (
        <FocusableBox
          href={href}
          display="flex"
          flexDirection="column"
          paddingY={3}
          paddingX={4}
          borderRadius="large"
          borderColor={borderColor}
          borderWidth="standard"
          height="full"
          background="white"
        >
          <Text
            as="h3"
            variant="h3"
            color={textColor}
            truncate={truncateHeading}
            title={heading}
          >
            {heading}
          </Text>
          <Text paddingTop={1}>{text}</Text>

          {hasTags && (
            <Box paddingTop={3}>
              <Inline space={["smallGutter", "smallGutter", "gutter"]}>
                {tags.map((tag) => (
                  <Tag
                    key={tag.label}
                    disabled={tag.disabled}
                    outlined={!tag.href}
                    variant={tagVariant}
                    href={tag.href}
                    onClick={tag.onClick}
                    truncate={true}
                  >
                    {tag.label}
                  </Tag>
                ))}
              </Inline>
            </Box>
          )}
        </FocusableBox>
      )}

      {image && (
        <FocusableBox
          href={href}
          display="flex"
          flexDirection="row"
          paddingY={3}
          paddingX={4}
          borderRadius="large"
          borderColor={borderColor}
          borderWidth="standard"
          height="full"
          background="white"
        >
          <Box>
            <Box color={textColor} style={{ fontSize: "1.7em" }}>
              {heading}
            </Box>
            <Box style={{ fontSize: "1.2em" }} paddingTop={1}>
              {text}
            </Box>
            {small && (
              <div style={center}>
                <img
                  alt={imageAlt}
                  src={image}
                  width="150px"
                  height="150px"
                  style={{ maxHeight: "150px", maxWidth: "150px" }}
                />
              </div>
            )}
          </Box>
          {!small && (
            <div style={center}>
              <img
                alt={imageAlt}
                src={image}
                width="150px"
                height="150px"
                style={{ maxHeight: "150px", maxWidth: "150px" }}
              />
            </div>
          )}
          {hasTags && (
            <Box paddingTop={3}>
              <Inline space={["smallGutter", "smallGutter", "gutter"]}>
                {tags.map((tag) => (
                  <Tag
                    key={tag.label}
                    disabled={tag.disabled}
                    outlined={!tag.href}
                    variant={tagVariant}
                    href={tag.href}
                    onClick={tag.onClick}
                    truncate={true}
                  >
                    {tag.label}
                  </Tag>
                ))}
              </Inline>
            </Box>
          )}
        </FocusableBox>
      )}
    </Box>
  );
};
