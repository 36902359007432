import React, { lazy, Suspense } from "react"
import { AnimatePresence } from "framer-motion"
import { Switch, Route, useLocation } from "react-router-dom"

import App from "./App"
import { ScrollToTop } from "./components"
import { Loading } from "./components/"
import EndurskodunGreinasvida from "scenes/EndurskodunGreinasvida/EndurskodunGreinasvida"
import Leikskola from "scenes/Leikskola/Leikskola"

const Home = lazy(() => import("./scenes/Home/Home"))
const Adalnamskra = lazy(() => import("./scenes/Adalnamskra/Adalnamskra"))
const PageNotFound = lazy(() => import("./scenes/PageNotFound/PageNotFound"))
const Kaflar = lazy(() => import("./scenes/Kaflar/Kaflar"))
const KaflarL = lazy(() => import("./scenes/KaflarL/Kaflar"))
const Posts = lazy(() => import("./scenes/Posts/Posts"))
const Search = lazy(() => import("./scenes/Search/Search"))
const Namsefni = lazy(() => import("./scenes/Namsefni/Namsefni"))
// const Tilraun = lazy(() => import("./scenes/Tilraun/Tilraun"));

export default function Routes() {
  const location = useLocation()
  return (
    <App>
      <AnimatePresence>
        <ScrollToTop />
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          </Route>

          <Route exact path="/adalnamskra-grunnskola">
            <Suspense fallback={<Loading />}>
              <Adalnamskra lang={"en-gb"} />
            </Suspense>
          </Route>

          <Route exact path="/adalnamskra-grunnskola/:value">
            <Suspense fallback={<Loading />}>
              <Kaflar lang={"en-gb"} />
            </Suspense>
          </Route>

          <Route exact path="/adalnamskra-leikskola">
            <Suspense fallback={<Loading />}>
              <Leikskola lang={"en-gb"} />
            </Suspense>
          </Route>

          <Route exact path="/adalnamskra-leikskola/:value">
            <Suspense fallback={<Loading />}>
              <KaflarL lang={"en-gb"} />
            </Suspense>
          </Route>

          <Route exact path="/endurskodun-greinasvida">
            <Suspense fallback={<Loading />}>
              <EndurskodunGreinasvida />
            </Suspense>
          </Route>

          <Route exact path="/en/adalnamskra-grunnskola">
            <Suspense fallback={<Loading />}>
              <Adalnamskra lang={"en-us"} />
            </Suspense>
          </Route>

          <Route exact path="/en/adalnamskra-grunnskola/:value">
            <Suspense fallback={<Loading />}>
              <Kaflar lang={"en-us"} />
            </Suspense>
          </Route>

          <Route exact path="/en/adalnamskra-leikskola">
            <Suspense fallback={<Loading />}>
              <Leikskola lang={"en-us"} />
            </Suspense>
          </Route>

          <Route exact path="/en/adalnamskra-leikskola/:value">
            <Suspense fallback={<Loading />}>
              <KaflarL lang={"en-us"} />
            </Suspense>
          </Route>

          <Route path="/greinar/:value">
            <Suspense fallback={<Loading />}>
              <Posts />
            </Suspense>
          </Route>

          <Route exact path="/leit">
            <Suspense fallback={<Loading ShowSearchbar={false} />}>
              <Search />
            </Suspense>
          </Route>

          <Route path="/404">
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          </Route>

          <Route exact path="/:valueOne/:valueTwo">
            <Suspense fallback={<Loading />}>
              <Namsefni />
            </Suspense>
          </Route>

          <Route exact path="/greinar">
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          </Route>

          {/* <Route exact path="/tilraun">
            <Suspense fallback={<Loading />}>
              <Tilraun />
            </Suspense>
          </Route> */}

          <Route default>
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          </Route>
        </Switch>
      </AnimatePresence>
    </App>
  )
}
