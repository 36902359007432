/* 
ScrollButton: 
When clicked scrolls to the top of the page.
*/

import React from "react";
import { Icon } from "@island.is/core";

import * as styles from "./ScrollButton.treat";

const ScrollButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      className={styles.button}
      onClick={scrollToTop}
      aria-label="Scroll Button"
    >
      <Icon color="currentColor" icon="arrowUp" size="large" type="outline" />
    </button>
  );
};

export default ScrollButton;
