/*
Cleanes text, might be deprecated.
Takes string input and converts it into url friendly format.
*/

function replaceText(str: string) {
  var temp = str;
  // str = str.replace(/[^a-z0-9-]/g, "");
  temp = temp.replace(/[ ]/g, "-");
  temp = temp.replace(/[æ]/g, "a");
  temp = temp.replace(/[ð]/g, "d");
  temp = temp.replace(/[þ]/g, "th");
  temp = temp.replace(/[ö]/g, "o");
  temp = temp.replace(/[í]/g, "i");
  temp = temp.replace(/[ý]/g, "y");
  temp = temp.replace(/[é]/g, "e");
  temp = temp.replace(/[ú]/g, "u");
  temp = temp.replace(/[á]/g, "a");
  temp = temp.replace(/[ó]/g, "o");
  temp = temp.replace(/[.]/g, "-");
  temp = temp.replace(/[:]/g, "");
  temp = temp.replace(/[,]/g, "-");
  return temp;
}

function cleanText(str: any) {
  var temp: any;
  if (typeof str === "string") {
    temp = replaceText(str.toLowerCase());
  } else {
    temp = replaceText(str[0][0].toLowerCase());
  }
  return temp;
}

export default cleanText;
