/*
This is for displaying the Document.
This file is finding the current text we want to display
on adalnamskra/kafli. It finds the all of the text for the 
slice we want to show, the name of the chapter and the number.
*/

function checkOrder(data: any, dir: string) {
  var HeftiProps: any;
  var OrderNumber: number;
  var i = 0;
  var leni = data.length;
  while (i < leni) {
    if (data[i].dir[0].text === dir) {
      HeftiProps = data[i].body;
      OrderNumber = data[i].number;
    }
    i++;
  }
  return [HeftiProps, OrderNumber];
}

export default checkOrder;
