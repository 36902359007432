/*
  Displays data in the footer on the bottom of pages.
  */

import React from "react"
import { GridContainer, GridRow, GridColumn, Box } from "@island.is/core"

import { FooterLink, FooterText, FooterLogo, ShareSite } from "./Components"
import * as styles from "./Footer.treat"

interface FooterProps {
  Data: any
}

const Footer = ({ Data = { slice_type: "error" } }: FooterProps) => {
  return (
    <footer className={styles.root}>
      <Box paddingTop={4} paddingBottom={4} marginTop={20}>
        <GridContainer>
          <GridRow>
            <GridColumn>
              {Data.map((data: any, index: number) => {
                switch (true) {
                  case data.slice_type === "share_site":
                    return <ShareSite key={index} Data={data} />

                  default:
                    return null
                }
              })}
            </GridColumn>
          </GridRow>
          <GridRow>
            {Data.map((data: any, index: number) => {
              switch (true) {
                case data.slice_type === "footer_text":
                  return <FooterText key={index} Data={data} />

                case data.slice_type === "footer_link":
                  return <FooterLink key={index} Data={data} />

                case data.slice_type === "logo":
                  return <FooterLogo key={index} Data={data} />

                default:
                  return null
              }
            })}
          </GridRow>
        </GridContainer>
      </Box>
    </footer>
  )
}

export default Footer
