export type Type = "filled" | "outline";
export type Icon =
  | "accessibility"
  | "add"
  | "arrowForward"
  | "arrowBack"
  | "arrowUp"
  | "arrowDown"
  | "attach"
  | "business"
  | "calendar"
  | "call"
  | "car"
  | "caretDown"
  | "caretUp"
  | "cellular"
  | "chatbubble"
  | "checkmark"
  | "checkmarkCircle"
  | "chevronBack"
  | "chevronUp"
  | "chevronDown"
  | "chevronForward"
  | "closeCircle"
  | "close"
  | "copy"
  | "document"
  | "documents"
  | "download"
  | "ellipse"
  | "ellipsisHorizontal"
  | "ellipsisVertical"
  | "eye"
  | "fileTrayFull"
  | "filter"
  | "heart"
  | "home"
  | "informationCircle"
  | "lockClosed"
  | "logOut"
  | "mail"
  | "menu"
  | "notifications"
  | "open"
  | "pencil"
  | "people"
  | "person"
  | "playCircle"
  | "print"
  | "reader"
  | "receipt"
  | "removeCircle"
  | "school"
  | "search"
  | "settings"
  | "time"
  | "trash"
  | "wallet"
  | "warning"
  | "reload"
  | "remove";
// eslint-disable-next-line
export default {
  filled: {
    accessibility: "Accessibility",
    add: "Add",
    arrowForward: "ArrowForward",
    arrowBack: "ArrowBack",
    arrowDown: "ArrowDown",
    arrowUp: "ArrowUp",
    attach: "Attach",
    business: "Business",
    calendar: "Calendar",
    call: "Call",
    car: "Car",
    caretDown: "CaretDown",
    caretUp: "CaretUp",
    cellular: "Cellular",
    chatbubble: "Chatbubble",
    checkmark: "Checkmark",
    checkmarkCircle: "CheckmarkCircle",
    chevronBack: "ChevronBack",
    chevronDown: "ChevronDown",
    chevronUp: "ChevronUp",
    chevronForward: "ChevronForward",
    closeCircle: "CloseCircle",
    close: "Close",
    copy: "Copy",
    document: "Document",
    documents: "Documents",
    download: "Download",
    ellipse: "Ellipse",
    ellipsisHorizontal: "EllipsisHorizontal",
    ellipsisVertical: "EllipsisVertical",
    eye: "Eye",
    fileTrayFull: "FileTrayFull",
    filter: "Filter",
    heart: "Heart",
    home: "Home",
    informationCircle: "InformationCircle",
    lockClosed: "LockClosed",
    logOut: "LogOut",
    mail: "Mail",
    menu: "Menu",
    notifications: "Notifications",
    open: "Open",
    pencil: "Pencil",
    people: "People",
    person: "Person",
    playCircle: "PlayCircle",
    print: "Print",
    reader: "Reader",
    receipt: "Receipt",
    removeCircle: "RemoveCircle",
    school: "School",
    search: "Search",
    settings: "Settings",
    trash: "Trash",
    wallet: "Wallet",
    warning: "Warning",
    reload: "Reload",
    remove: "Remove",
    time: "Time",
  },
  outline: {
    accessibility: "AccessibilityOutline",
    add: "AddOutline",
    arrowForward: "ArrowForward",
    arrowBack: "ArrowBack",
    arrowDown: "ArrowDown",
    arrowUp: "ArrowUp",
    attach: "AttachOutline",
    business: "BusinessOutline",
    calendar: "CalendarOutline",
    call: "CallOutline",
    car: "CarOutline",
    caretDown: "CaretDownOutline",
    caretUp: "CaretUpOutline",
    cellular: "CellularOutline",
    chatbubble: "ChatbubbleOutline",
    checkmark: "Checkmark",
    checkmarkCircle: "CheckmarkCircleOutline",
    chevronBack: "ChevronBack",
    chevronUp: "ChevronUp",
    chevronDown: "ChevronDown",
    chevronForward: "ChevronForward",
    closeCircle: "CloseCircleOutline",
    close: "Close",
    copy: "CopyOutline",
    document: "DocumentOutline",
    documents: "DocumentsOutline",
    download: "DownloadOutline",
    ellipse: "EllipseOutline",
    ellipsisHorizontal: "EllipsisHorizontalOutline",
    ellipsisVertical: "EllipsisVerticalOutline",
    eye: "EyeOutline",
    fileTrayFull: "FileTrayFullOutline",
    filter: "FilterOutline",
    heart: "HeartOutline",
    home: "HomeOutline",
    informationCircle: "InformationCircleOutline",
    lockClosed: "LockClosedOutline",
    logOut: "LogOutOutline",
    mail: "MailOutline",
    menu: "MenuOutline",
    notifications: "NotificationsOutline",
    open: "OpenOutline",
    pencil: "PencilOutline",
    people: "PeopleOutline",
    person: "PersonOutline",
    playCircle: "PlayCircleOutline",
    print: "PrintOutline",
    reader: "ReaderOutline",
    receipt: "ReceiptOutline",
    removeCircle: "RemoveCircleOutline",
    school: "SchoolOutline",
    search: "SearchOutline",
    settings: "SettingsOutline",
    trash: "TrashOutline",
    wallet: "WalletOutline",
    warning: "WarningOutline",
    reload: "ReloadOutline",
    remove: "Remove",
    time: "TimeOutline",
  },
};
