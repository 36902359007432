/*
This is for cleaning and dividing the data from Prismic for the 
Posts Scene.
*/

function getPostsValue(doc: any) {
  var HeaderProps: any
  var FooterProps: any
  var TooltipsProps: any
  var PostsProps = []

  var i = 0
  var leni = doc.length
  while (i < leni) {
    switch (true) {
      case doc[i].type === "header":
        HeaderProps = doc[i].data
        break

      case doc[i].type === "footer":
        FooterProps = doc[i].data.body
        break

      case doc[i].type === "tooltips":
        TooltipsProps = doc[i].data.body
        break

      case doc[i].type === "posts":
        PostsProps.push(doc[i].data)
        break

      default:
        break
    }
    i++
  }

  return [HeaderProps, FooterProps, PostsProps, TooltipsProps]
}

export default getPostsValue
