/*
This is for displaying the Posts.
This file is finding the current text we want to display
on posts/:value and the Title of the current post.
*/

function checkPost(data: any, dir: string) {
  var CurrentPost: any;
  var ShowTooltips = true;
  var i = 0;
  var leni = data.length;
  while (i < leni) {
    if (data[i].dir[0].text === dir) {
      CurrentPost = data[i].body;
      ShowTooltips = data[i].show_tooltips;
    }
    i++;
  }
  if (ShowTooltips === null) {
    ShowTooltips = true;
  }
  return [CurrentPost, ShowTooltips];
}

export default checkPost;
