/*
Paginate:
Transfer between pages in Kaflar.
*/

import React, { useState } from "react"
import {
  GridContainer,
  GridRow,
  GridColumn,
  Box,
  Pagination,
} from "@island.is/core"
import { useHistory } from "react-router-dom"

interface PaginateProps {
  /*
  Current page number.
   */
  CurrentPage: number
  /*
  How many total pages to display.
   */
  HowManyPages?: number
  /*
  Array of objects for info about all the pages.
   */
  AllPages: [{ href: string; index: number; title: string; type: string }]
}

const Paginate = ({
  CurrentPage = 4,
  HowManyPages = 30,
  AllPages,
}: PaginateProps) => {
  const [order, setOrder] = useState(CurrentPage)
  let history = useHistory()
  console.log(order)
  return (
    <Box className={"noprint"}>
      <GridContainer>
        <GridRow>
          <GridColumn span="12/12">
            <Box paddingBottom={2} paddingTop={2}>
              <Pagination
                page={order}
                totalPages={HowManyPages}
                renderLink={(page, className, children) => (
                  <Box
                    cursor="pointer"
                    style={{ color: "black" }}
                    className={className}
                    onClick={() => {
                      setOrder(page)
                      var i = 0
                      var leni = AllPages.length
                      while (i < leni) {
                        if (AllPages[i].index === page)
                          history.push(AllPages[i].href)
                        i++
                      }
                    }}
                  >
                    {children}
                  </Box>
                )}
              />
            </Box>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  )
}

export default Paginate
