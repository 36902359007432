import React from "react"
import { Box, Text } from "@island.is/core"
import { Link } from "react-router-dom"

import * as styles from "./PageNotFoundOffline.treat"

const PageNotFoundOffline = () => (
  <Box className={styles.root}>
    <Box>
      <Text variant="h2">Aðalnámskrá Grunnskóla</Text>
      <Text variant="h2">404</Text>
      <Text variant="h3">
        AAA Eitthvað hefur farið úrskeiðis / Something has gone wrong.
      </Text>
      <Text variant="h3">Síða eða skjal fannst ekki.</Text>
      <Text>
        Eftirfarandi ástæður geta verið fyrir því að síða/skjal fannst ekki:
      </Text>
      <Text>● Upplýsingar hafa verið fjarlægðar</Text>
      <Text>● Vefslóð ekki rétt skrifuð</Text>
      <Text>● Síðan er ekki lengur til</Text>
      <Text>● Villa er á síðunni</Text>
      <Text>
        Teljir þú að síða eða skjal eigi sannarlega að birtast má endilega senda
        ábendingu á{" "}
        <a href="https://mms.is/hafa-samband" target="_blank" rel="noreferrer">
          mms.is/hafa-samband
        </a>
      </Text>
      <Link to="/">Ýttu hér til að fara aftur á Forsíðu</Link>
    </Box>
  </Box>
)

export default PageNotFoundOffline
