/*
  InfoBanner: Displays a alert message on top of every screen.
  Usefull to let people know about an update or that something
  is not working correctly.
  */

import React from "react";
import { Box, AlertBanner } from "@island.is/core";

interface InfoBannerProps {
  /*
  Data in Prismic format.
  */
  Data: any;
}

const InfoBanner = ({
  Data = {
    display: false,
    banner_title: { text: "" },
    banner_text: { text: "" },
    banner_variant: "default",
  },
}: InfoBannerProps) => {
  return (
    <Box>
      {Data.display && (
        <AlertBanner
          title={Data.banner_title[0].text}
          description={Data.banner_text[0].text}
          variant={Data.banner_variant}
        />
      )}
    </Box>
  );
};

export default InfoBanner;
