import React from "react";
import { Box, GridContainer, GridRow, GridColumn } from "@island.is/core";
import { useMediaQuery } from "react-responsive";
import parse from "html-react-parser";

interface ImgSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
}

const ImgSlice = ({ Data }: ImgSliceProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 1000px)" });
  return (
    <>
      {Data.length === 1 && (
        <>
          {Data.map((data: any, index: number) => (
            <Box key={index} textAlign={"center"}>
              <img
                src={data.img.url}
                alt={data.img.alt}
                style={{ height: "auto", maxWidth: "100%" }}
              />
              {data.text[0]?.text.length > 0 && (
                <p>{parse("<i>" + data.text[0]?.text + "</i>")}</p>
              )}
            </Box>
          ))}
        </>
      )}
      {Data.length > 1 && (
        <GridContainer>
          <GridRow>
            {Data.map((data: any, index: number) => (
              <GridColumn
                span={
                  Data.length === 2
                    ? isSmall
                      ? "12/12"
                      : "6/12"
                    : isSmall
                    ? ["12/12", "6/12"]
                    : ["6/12", "4/12"]
                }
                key={index}
              >
                <Box textAlign={"center"}>
                  <img src={data.img.url} alt={data.img.alt} />
                  {data.text[0]?.text.length > 0 && (
                    <p>{parse("<i>" + data.text[0]?.text + "</i>")}</p>
                  )}
                </Box>
              </GridColumn>
            ))}
          </GridRow>
        </GridContainer>
      )}
    </>
  );
};

export default ImgSlice;
