/*
This is for the Table of Content.
Makes an array of objects with ids and titles.
Later we assign every title with the id so when we click
a link on TOC we will be moved to the correct place.
*/

import { cleanText } from "../../";

function getHeading(data: any) {
  var temp = [];
  var i = 0;
  var leni = data.length;
  while (i < leni) {
    if (data[i].slice_type === "title_toc") {
      var tempStr = cleanText(data[i].primary.title[0].text);
      temp.push({
        headingId: tempStr,
        headingTitle: data[i].primary.title[0].text,
      });
    }
    i++;
  }
  return temp;
}

export default getHeading;
