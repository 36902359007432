/*
First checks if the text property is included, this is necessary beacuse Prisimc does not update
its files automatically, so some files could have no text property.

Then we check if its undefined or not, to see if we should render text or not.
*/

import React from "react";
import { Box, GridContainer, GridRow, GridColumn } from "@island.is/core";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";

import { addTooltips, makeTooltips } from "../../../../utils";

interface ImgTextSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
  /*
  List of words and definitions for tooltips.
  */
  Tooltips?: any;
  /*
  To show or not show tooltips in this component.
   */
  ShowTooltips?: boolean;
}

const ImgTextSlice = ({
  Data,
  Tooltips = "",
  ShowTooltips = true,
}: ImgTextSliceProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 550px)" });

  return (
    <Box>
      {Data.map((data: any, index: number) => (
        <Box key={index}>
          <GridContainer>
            <GridRow>
              <GridColumn span={isSmall ? "12/12" : "4/12"}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  paddingBottom={2}
                >
                  <img src={data.img.url} alt={data.img.alt} />
                </Box>
              </GridColumn>
              <GridColumn span={isSmall ? "12/12" : "8/12"}>
                {data.text?.map((data: any, index: number) => (
                  <Box
                    key={index}
                    paddingBottom={2}
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  >
                    {ShowTooltips &&
                      parse(
                        addTooltips(
                          data.text?.replace(/\s+/g, " ").trim(),
                          makeTooltips(Tooltips)
                        )
                      )}
                    {!ShowTooltips &&
                      parse(data.text?.replace(/\s+/g, " ").trim())}
                  </Box>
                ))}
              </GridColumn>
            </GridRow>
          </GridContainer>
        </Box>
      ))}
    </Box>
  );
};

export default ImgTextSlice;
