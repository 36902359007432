import React from "react";
import { Box, Accordion, AccordionItem, GridColumn } from "@island.is/core";
import { RichText } from "prismic-reactjs";

import { paddingSerializer } from "../../../../utils";
import * as styles from "./AccordianSlice.treat";

interface AccordianSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
}

const AccordianSlice = ({ Data }: AccordianSliceProps) => {
  return (
    <>
      {Data && (
        <Box paddingBottom={2}>
          <GridColumn span={["12/12", "12/12", "12/12"]} className={styles.root}>
            <Accordion singleExpand>
              {Data.map((data: any, index: number) => (
                <Box key={index}>
                  <AccordionItem
                    id={index.toString()}
                    label={data.title[0].text}
                  >
                    <RichText
                      render={data.text}
                      htmlSerializer={paddingSerializer}
                    />
                  </AccordionItem>
                </Box>
              ))}
            </Accordion>
          </GridColumn>
        </Box>
      )}
    </>
  );
};

export default AccordianSlice;
