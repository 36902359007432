import React from "react";

import "./styles/Navbar.css";
import "./styles/ScrollButton.css";
import "./styles/Tooltips.css";
import "./styles/Nav.css";
import "./styles/Hover.css";
import "./styles/Printing.css";

interface PropTypes {
  children: React.ReactNode;
}

function App({ children }: PropTypes) {
  return <div>{children}</div>;
}

export default App;
