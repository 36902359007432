/*
This is for Tooltips.
Gets text and list of tooltips and adds the tooltips as spans to
the text and then returns the text with the tooltips.
Also change all occurences with both lowercase and
uppercase first letter and when there is a . after the word.
*/

function firstLetterUp(data: string) {
  return data.charAt(0).toUpperCase() + data.slice(1);
}

function firstLetterDown(data: string) {
  return data.charAt(0).toLowerCase() + data.slice(1);
}

function replace(word: string, content: string) {
  return (
    "<span class='tooltip'>" +
    word +
    "<span class='tooltip-text rs_skip noprint' style='text-align:left'>" +
    content +
    "</span></span>"
  );
}

function addTooltips(text: any, listTooltips: any) {
  var temp = text.split(" ");
  var i = 0;
  var leni = temp.length;
  var lenj = listTooltips.length;
  while (i < leni) {
    var j = 0;
    while (j < lenj) {
      switch (true) {
        case temp[i] === firstLetterDown(listTooltips[j].word):
          temp[i] = replace(
            firstLetterDown(listTooltips[j].word),
            listTooltips[j].content
          );
          listTooltips[j].word = "";
          break;

        case temp[i] === firstLetterUp(listTooltips[j].word):
          temp[i] = replace(
            firstLetterUp(listTooltips[j].word),
            listTooltips[j].content
          );
          listTooltips[j].word = "";
          break;

        case temp[i] === listTooltips[j].word + ".":
          temp[i] = replace(
            listTooltips[j].word + ".",
            listTooltips[j].content
          );
          listTooltips[j].word = "";
          break;

        default:
          break;
      }
      j++;
    }
    i++;
  }
  return temp.join(" ");
}

export default addTooltips;
