export default function getHomeValues(doc: any) {
  var HeaderProps: any;
  var HomeProps: any;
  var FooterProps: any;

  var i = 0;
  var leni = doc.length;
  while (i < leni) {
    switch (true) {
      case doc[i].type === "header":
        HeaderProps = doc[i].data;
        break;

      case doc[i].type === "footer":
        FooterProps = doc[i].data.body;
        break;

      case doc[i].type === "home":
        HomeProps = doc[i].data.body;
        break;

      default:
        break;
    }
    i++;
  }

  return [HeaderProps, HomeProps, FooterProps];
}
