/*
This is for cleaning and dividing the data from Prismic for the Adalnamskra Scene.
We sort the chapters as they do not arrive in correct order.
We also add a direction to the chapters so when the links get pressed we get moved 
to the correct page.

Added Formal: Same concept for the formal as for the chapters,
cleaning the data and then sorting
*/

function getLeikskolaValues(doc: any) {
  var HeaderProps: any
  var FooterProps: any
  var LeikskolaProps: any
  var AllChapterNames = []

  var i = 0
  var leni = doc.length
  while (i < leni) {
    switch (true) {
      case doc[i].type === "header":
        HeaderProps = doc[i].data
        break

      case doc[i].type === "footer":
        FooterProps = doc[i].data.body
        break

      case doc[i].type === "leikskola":
        LeikskolaProps = doc[i].data
        break

      case doc[i].type === "adalnamskrakafli":
        AllChapterNames.push({
          index: doc[i].data.number,
          href: "/adalnamskra-leikskola/" + doc[i].data.dir[0].text,
          title: doc[i].data.name[0].text,
          type: doc[i].data.type,
        })
        break

      default:
        break
    }
    AllChapterNames.sort(
      (firstItem, secondItem) => firstItem.index - secondItem.index
    )
    i++
  }

  return [HeaderProps, FooterProps, LeikskolaProps, AllChapterNames]
}

export default getLeikskolaValues
