/*
Header: Displays data in the header, top part of every page.
*/

import React from "react"
import { GridContainer, Box } from "@island.is/core"
import { useHistory, useLocation } from "react-router-dom"

import * as styles from "./Header.treat"

interface HeaderProps {
  /*
  Data in Prismic format.
  */
  Data?: any
}

const Header = ({
  Data = {
    url: "https://images.prismic.io/adalnamskra/f58d86d1-2e33-4236-91a6-6462546dc27a_grunnskola-logo.png?auto=compress,format",
    leikskoli:
      "https://images.prismic.io/adalnamskra/420a408a-b82b-447a-869e-f7cf5d5e6d3a_leikskola-logo.png?auto=compress",
    alt: "Aðalnámskrá Grunnskóla mynd",
    leikalt: "Aðalnámskrá Grunnskóla mynd",
  },
}: HeaderProps) => {
  let history = useHistory()
  const location = useLocation()
  console.log(location.pathname)
  return (
    <>
      <header className={styles.root}>
        <GridContainer>
          {Data && (
            <Box paddingTop={1}>
              {location.pathname !== "/" &&
                !location.pathname.includes("/greinar") && (
                  <img
                    className={styles.headerImg}
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/")}
                    src={
                      location.pathname.includes("/adalnamskra-leikskola")
                        ? "https://images.prismic.io/adalnamskra/420a408a-b82b-447a-869e-f7cf5d5e6d3a_leikskola-logo.png?auto=compress"
                        : "https://images.prismic.io/adalnamskra/f58d86d1-2e33-4236-91a6-6462546dc27a_grunnskola-logo.png?auto=compress,format"
                    }
                    alt={Data.alt}
                    width={"250px"}
                  />
                )}
            </Box>
          )}
        </GridContainer>
      </header>
    </>
  )
}

export default Header
