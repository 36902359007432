import React from "react"
import { Box, GridContainer, GridColumn, GridRow } from "@island.is/core"
import StickyBox from "react-sticky-box"
import { useMediaQuery } from "react-responsive"

import {
  Header,
  HeaderLink,
  ScrollButton,
  SearchBar,
  InfoBanner,
} from "../../components"

import * as styles from "./Wrapper.treat"

interface WrapperProps {
  /*
  Array of Objects for the header component.
   */
  HeaderProps: any
  /*
  Should the Header be sticky while scrolling.
   */
  Sticky?: boolean
  /*
  Show the searchbar inside the header or not.
   */
  ShowSearchbar?: boolean
  /*
  Children component.
   */
  children: React.ReactNode
}

const Wrapper = ({
  HeaderProps = { img: "", groupdir: "", body: "" },
  children,
  Sticky = true,
  ShowSearchbar = true,
}: WrapperProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" })
  const noSticky = useMediaQuery({ query: "(max-width: 1200px)" })
  return (
    <Box>
      <InfoBanner Data={HeaderProps} />
      <Box className={"noprint"}>
        <Header Data={HeaderProps.img} />
      </Box>
      {Sticky && !noSticky && (
        <StickyBox className={styles.zindex}>
          <Box className={styles.background + " noprint"}>
            <GridContainer>
              <GridRow>
                <GridColumn span={isSmall ? "12/12" : "9/12"}>
                  <HeaderLink Data={HeaderProps.groupdir} />
                </GridColumn>
                {ShowSearchbar && (
                  <GridColumn span={isSmall ? "12/12" : "3/12"}>
                    <SearchBar />
                  </GridColumn>
                )}
              </GridRow>
            </GridContainer>
          </Box>
        </StickyBox>
      )}
      {(!Sticky || noSticky) && (
        <Box className={styles.background + " noprint"}>
          <GridContainer>
            <GridRow>
              <GridColumn span={isSmall ? "12/12" : "9/12"}>
                <HeaderLink Data={HeaderProps.groupdir} />
              </GridColumn>
              {ShowSearchbar && (
                <GridColumn span={isSmall ? "12/12" : "3/12"}>
                  <SearchBar />
                </GridColumn>
              )}
            </GridRow>
          </GridContainer>
        </Box>
      )}

      <Box>{children}</Box>

      <Box className={"noprint"}>
        <ScrollButton />
      </Box>
    </Box>
  )
}

export default Wrapper
