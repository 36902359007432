/*
This needs to be reworked.
*/

import React from "react";
import { Box, TableOfContents, Hidden } from "@island.is/core";

import { getHeading } from "../../utils";

interface TableContentProps {
  Data: any;
  Title: string;
  HideType: "above" | "below";
  HideBelow?: "sm" | "md" | "lg" | "xl";
  HideAbove?: "sm" | "md" | "lg" | "xs";
}

const TableContent = ({
  Data = { slice_type: "" },
  Title = "",
  HideType = "above",
  HideBelow = "md",
  HideAbove = "md",
}: TableContentProps) => {
  var links: any;
  var anyLinks = true;
  links = getHeading(Data);
  if (links.length === 0) {
    anyLinks = false;
  }

  const TypeBelow = () => (
    <Hidden below={HideBelow}>
      <Box>
        <TableOfContents
          tableOfContentsTitle={Title}
          headings={links}
          onClick={(selectedHeadingId) =>
            document.getElementById(selectedHeadingId).scrollIntoView()
          }
        />
      </Box>
    </Hidden>
  );

  const TypeAbove = () => (
    <Hidden above={HideAbove}>
      <Box>
        <TableOfContents
          tableOfContentsTitle={Title}
          headings={links}
          onClick={(selectedHeadingId) =>
            document.getElementById(selectedHeadingId).scrollIntoView()
          }
        />
      </Box>
    </Hidden>
  );

  return (
    <Box className={"noprint"}>
      {anyLinks && (
        <Box>
          {HideType === "below" && <TypeBelow />}
          {HideType === "above" && <TypeAbove />}
        </Box>
      )}
      {!anyLinks && <Box></Box>}
    </Box>
  );
};

export default TableContent;
