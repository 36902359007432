import React from "react";
import { GridColumn, Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";
import { useMediaQuery } from "react-responsive";

interface FooterProps {
  Data: any;
}

const Footer = ({ Data }: FooterProps) => {
  function isNotEmpty(obj: any) {
    for (var i in obj) return true;
    return false;
  }
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <GridColumn span={isSmall ? "12/12" : "4/12"} paddingBottom={2}>
      {isNotEmpty(Data.primary.img) && (
        <Box>
          <a
            href={Data.primary.imgdir[0].text}
            target="_blank"
            rel="noreferrer"
          >
            <img src={Data.primary.img.url} alt={Data.primary.img.alt} />
          </a>
        </Box>
      )}
      <RichText render={Data.primary.title} />
      {Data.items.map((data: any, index: number) => (
        <Box key={index}>
          <RichText render={data.text} />
        </Box>
      ))}
    </GridColumn>
  );
};

export default Footer;
