import React from "react";
import { Box, GridColumn } from "@island.is/core";
import { useMediaQuery } from "react-responsive";

import { BulletList } from "../";

interface ThreeListProps {
  Data: any;
  Tooltips?: any;
}

const ThreeList = ({ Data, Tooltips }: ThreeListProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 800px)" });
  return (
    <GridColumn
      span={isSmall ? "12/12" : "4/12"}
      paddingBottom={isSmall ? 3 : 0}
    >
      <Box padding={1} style={{ border: "2px solid #00ABA2" }}>
        {Data.map((data: any, index: number) => (
          <Box key={index} paddingBottom={1}>
            <BulletList data={data} Tooltips={Tooltips} />
          </Box>
        ))}
      </Box>
    </GridColumn>
  );
};

export default ThreeList;
