import React from "react";
import {
  Box,
  GridColumn,
  GridContainer,
  GridRow,
  VideoIframe,
} from "@island.is/core";
import { RichText } from "prismic-reactjs";
import { useMediaQuery } from "react-responsive";

interface VideoSliceProps {
  /*
  Data in Prismic format.
  */
  Data: any;
}

const VideoSlice = ({ Data }: VideoSliceProps) => {
  const isSmall = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <>
      {Data.length === 1 && (
        <Box paddingLeft={isSmall ? 0 : 20} paddingRight={isSmall ? 0 : 20}>
          <VideoIframe
            src={RichText.asText(Data[0].link)}
            title={RichText.asText(Data[0].title)}
          />
        </Box>
      )}
      {Data.length > 1 && (
        <GridContainer>
          <GridRow>
            {Data.map((data: any, index: number) => (
              <GridColumn span={isSmall ? "12/12" : "6/12"}>
                <Box key={index} paddingBottom={3}>
                  <VideoIframe
                    src={RichText.asText(data.link)}
                    title={RichText.asText(data.title)}
                  />
                </Box>
              </GridColumn>
            ))}
          </GridRow>
        </GridContainer>
      )}
    </>
  );
};

export default VideoSlice;
