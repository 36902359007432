/*
This is for cleaning and dividing the data from Prismic for the SearchOutput Scene.
*/

function getSearchValues(doc: any) {
  var HeaderProps: any
  var FooterProps: any
  var SearchProps = []

  var i = 0
  var leni = doc.length
  while (i < leni) {
    switch (true) {
      case doc[i].type === "header":
        HeaderProps = doc[i].data
        break

      case doc[i].type === "footer":
        FooterProps = doc[i].data.body
        break

      case doc[i].type === "adalnamskrakafli":
        SearchProps.push(doc[i])
        break

      default:
        break
    }
    i++
  }

  return [HeaderProps, FooterProps, SearchProps]
}

export default getSearchValues
