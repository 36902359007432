/*
Documents: Handles displaying text and other information.
*/
import React from "react";
import { Box } from "@island.is/core";

import {
  TextSlice,
  ImgSlice,
  ListSlice,
  TitleSlice,
  ImgTextSlice,
  ThreeListSlice,
  AccordianSlice,
  VideoSlice,
  ErrorHandler,
} from "./Components";
import { TextToVoice } from "../../components";

interface DocumentsProps {
  /*
  Data from Prismic.
   */
  Data: any;
  /*
  Prismic data, with words
  and definition for tooltips.
   */
  TooltipsWords?: any;
  /*
  Show tooltips on the page or not.
   */
  ShowTooltips?: boolean;
}
const Documents = ({
  Data = [{ data: { primary: "error", items: "error" }, slice_type: "error" }],
  TooltipsWords,
  ShowTooltips = true,
}: DocumentsProps) => {
  if (Data.length === 0) {
    Data = [
      { data: { primary: "error", items: "error" }, slice_type: "error" },
    ];
  }
  return (
    <Box>
      <TextToVoice>
        {Data.map((data: any, index: number) => {
          switch (true) {
            case data.slice_type === "title":
              return (
                <Box key={index} paddingBottom={2}>
                  <TitleSlice Data={data.primary} Type={"title"} />
                </Box>
              );

            case data.slice_type === "title_toc":
              return (
                <Box key={index} paddingBottom={2}>
                  <TitleSlice Data={data.primary} Type={"title_toc"} />
                </Box>
              );

            case data.slice_type === "text":
              return (
                <Box key={index} paddingBottom={2}>
                  <TextSlice
                    Data={data.items}
                    Tooltips={TooltipsWords}
                    ShowTooltips={ShowTooltips}
                  />
                </Box>
              );

            case data.slice_type === "three_list":
              return (
                <Box key={index} paddingBottom={2}>
                  <ThreeListSlice
                    Data={data.items}
                    Tooltips={TooltipsWords}
                    ShowTooltips={ShowTooltips}
                  />
                </Box>
              );

            case data.slice_type === "image":
              return (
                <Box key={index} paddingBottom={2}>
                  <ImgSlice Data={data.items} />
                </Box>
              );

            case data.slice_type === "image_with_text":
              return (
                <Box key={index} paddingBottom={2}>
                  <ImgTextSlice
                    Data={data.items}
                    Tooltips={TooltipsWords}
                    ShowTooltips={ShowTooltips}
                  />
                </Box>
              );

            case data.slice_type === "list":
              return (
                <Box key={index} paddingBottom={2}>
                  <ListSlice
                    Data={data.items}
                    Tooltips={TooltipsWords}
                    ShowTooltips={ShowTooltips}
                  />
                </Box>
              );

            case data.slice_type === "accordian":
              return (
                <Box key={index} paddingBottom={2}>
                  <AccordianSlice Data={data.items} />
                </Box>
              );

            case data.slice_type === "video":
              return (
                <Box key={index} paddingBottom={2}>
                  <VideoSlice Data={data.items} />
                </Box>
              );

            case data.slice_type === "error":
              return (
                <Box key={index} paddingBottom={20} paddingTop={2}>
                  <ErrorHandler />
                </Box>
              );

            default:
              return null;
          }
        })}
      </TextToVoice>
    </Box>
  );
};

export default Documents;
