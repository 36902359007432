/*
This is for displaying the Document.
This file is finding the current text we want to display
on adalnamskra-grunnskoli/kafli. It finds the all of the text for the 
slice we want to show, the name of the chapter and the number.
*/

function checkChapter(data: any, dir: string) {
  if (typeof data === "undefined") {
    return [[], [], []];
  }
  var DocumentsProps: any;
  var ChapterName: string;
  var ChapterNumber: number;
  var i = 0;
  var leni = data.length;
  while (i < leni) {
    if (data[i].dir[0].text === dir) {
      DocumentsProps = data[i].body;
      ChapterName = data[i].name[0].text;
      ChapterNumber = data[i].number;
    }
    i++;
  }
  return [DocumentsProps, ChapterName, ChapterNumber];
}

export default checkChapter;
