import React, { MouseEventHandler } from "react"
// import { RequireAtLeastOne } from 'type-fest'

import { Box } from "../Box/Box"
import { Text } from "../Text/Text"
import { Tag } from "../Tag/Tag"
import { FocusableBox } from "../FocusableBox/FocusableBox"

type ColorScheme = "blue" | "red"

export type TopicCardSize = "default" | "small"

interface TopicCardPropsBase {
  tag?: string
  colorScheme?: ColorScheme
  href?: string
  size?: TopicCardSize
  onClick?: MouseEventHandler
}

const colorSchemes = {
  blue: {
    backgroundColor: "seagreen300",
    textColor: "black",
    tagVariant: "darkerBlue",
    bordered: false,
  },
  red: {
    backgroundColor: "orange100",
    textColor: "dark400",
    tagVariant: "red",
    bordered: true,
  },
} as const

// jonasmms removed TopicCardProps and changed it with TopicCardPropsBase
export const TopicCard: React.FC<TopicCardPropsBase> = ({
  children,
  colorScheme = "blue",
  href,
  tag,
  size = "default",
  onClick,
}) => {
  return (
    <FocusableBox
      alignItems="center"
      background={colorSchemes[colorScheme].backgroundColor}
      borderRadius="large"
      display="flex"
      href={href}
      onClick={onClick}
      padding={size === "default" ? [2, 2, 3] : [2, 2, 2]}
      position="relative"
      width="full"
      style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
    >
      <Text variant="h4" as="span" color={colorSchemes[colorScheme].textColor}>
        {children}
      </Text>
      {tag && (
        <Box component="span" paddingLeft={2} marginLeft="auto">
          <Tag
            variant={colorSchemes[colorScheme].tagVariant}
            outlined={colorSchemes[colorScheme].bordered}
            disabled
          >
            {tag}
          </Tag>
        </Box>
      )}
    </FocusableBox>
  )
}
