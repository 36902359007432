import { Box } from "@island.is/core"

import { getLeikskolaValues } from "../../utils"
import { Wrapper, PageNotFoundOffline, Loading, Footer } from "../../components"
import { Nav } from "./components"
import { fetch } from "../../hooks"

interface LeikskolaProps {
  lang: "en-gb" | "en-us"
}

const Leikskola = ({ lang }: LeikskolaProps) => {
  const { doc, isLoading } = fetch({ url: "Leikskola", lang: lang })

  if (isLoading) {
    return <Loading />
  }
  if (!doc || doc.length === 0) {
    return <PageNotFoundOffline />
  }

  /* 
	AllChapterNames: List of all the chapter names in correct order.
	*/

  var [HeaderProps, FooterProps, LeikskolaProps, AllChapterNames] =
    getLeikskolaValues(doc)

  return (
    <Wrapper HeaderProps={HeaderProps} Sticky={false}>
      <Box paddingTop={4} paddingBottom={4}>
        <Nav
          Data={AllChapterNames}
          LeikskolaProps={LeikskolaProps}
          lang={lang}
        />
      </Box>
      <Box className={"noprint"}>
        <Footer Data={FooterProps} />
      </Box>
    </Wrapper>
  )
}

export default Leikskola
