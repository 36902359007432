/*
This is for sorting the navigation items on /adalnamskra-grunnskola
It sorts them into two arrays AlmennurHluti and Greinasvid.
*/

function sortNav(data: any, lang: string) {
  if (typeof data === "undefined") {
    return [[], [], []];
  }
  var AlmennurHluti = [];
  var Greinasvid = [];
  var Formali = [];
  var i = 0;
  var leni = data.length;

  switch (true) {
    case lang === "en-gb":
      while (i < leni) {
        switch (true) {
          case data[i].type === "Greinasvid":
            Greinasvid.push({ href: data[i].href, title: data[i].title });
            break;

          case data[i].type === "Almennur-Hluti":
            AlmennurHluti.push({
              href: data[i].href,
              title: data[i].title,
            });
            break;

          case data[i].type === "Formali":
            Formali.push({
              href: data[i].href,
              title: data[i].title,
            });
            break;
        }
        i++;
      }
      break;

    case lang === "en-us":
      while (i < leni) {
        switch (true) {
          case data[i].type === "Greinasvid":
            Greinasvid.push({
              href: "/en" + data[i].href,
              title: data[i].title,
            });
            break;

          case data[i].type === "Almennur-Hluti":
            AlmennurHluti.push({
              href: "/en" + data[i].href,
              title: data[i].title,
            });
            break;

          case data[i].type === "Formali":
            Formali.push({
              href: "/en" + data[i].href,
              title: data[i].title,
            });
            break;
        }
        i++;
      }
      break;
  }

  return [AlmennurHluti, Greinasvid, Formali];
}

export default sortNav;
