import "!style-loader!css-loader?{\"modules\":false,\"url\":false}!./Text.treat.1WU2psU.css";
export var truncate = '_3gGCB';
export var strikethrough = 'tQTOm';
export var base = '_2P3eG';
export var fontWeight = {light:'_3QIgv',regular:'hUmw8',medium:'_2QiIM',semiBold:'_1XyLc'};
export var lineHeight = {xs:'_1aX-6',sm:'_3bcVy',md:'_1uk3Q',lg:'_1gJTm',xl:'_2rzph'};
export var defaultFontWeights = {'default':'_2T8GU',h1:'_1W-1r',h2:'_3rCKf',h3:'_3xBdr',h4:'uEqva',h5:'_10h0F',small:'_2-8Fv',intro:'eqR-p',eyebrow:'_2C8UJ'};
export var defaultLineHeights = {'default':'_1a0bs',h1:'_2vGUw',h2:'_3tr2C',h3:'_30H7G',h4:'_1Py1R',h5:'_1aAcZ',small:'_3U_rw',intro:'l_QT4',eyebrow:'fU2VY'};
export var variants = (function(){var x={'default':{fontSize:{xs:16,md:18}},h1:{fontSize:{xs:32,md:42}},h2:{fontSize:{xs:26,md:34}},h3:{fontSize:{xs:20,md:24}},h4:{fontSize:{xs:18,md:20}},h5:{fontSize:undefined},small:{fontSize:{xs:12,md:14}},intro:{fontSize:undefined},eyebrow:{fontSize:undefined}};x.h5.fontSize=x['default'].fontSize;x.intro.fontSize=x.h3.fontSize;x.eyebrow.fontSize=x.small.fontSize;return x;}());
export var colors = {blue600:'_1oQay',blue400:'L9jP3',blue300:'_3U2gS',blue200:'_1-lG9',blue100:'_29Jfv',dark400:'kRAo-',dark300:'_6K1XG',dark200:'_264_Z',dark100:'_1pW19',red600:'_22ppH',red400:'_354mi',red300:'_2Jt1R',red200:'_2jStS',red100:'_3KaPY',orange100:'_1VdVg',blueberry600:'_1jyyW',blueberry400:'_1LKwa',blueberry300:'_3JGKZ',blueberry200:'fRRO2',blueberry100:'_3uzUv',purple600:'u3_1V',purple400:'_283yM',purple300:'_2pGub',purple200:'_2mnHJ',purple100:'_3H4_0',roseTinted600:'_5Jt4C',roseTinted400:'_16N75',roseTinted300:'_3dq9q',roseTinted200:'_3c6VB',roseTinted100:'A11OI',mint600:'_1itDd',mint400:'_3Bsnb',mint200:'_31YZm',mint300:'_3kbkI',mint100:'_1Y-Wx',yellow600:'_15eDM',yellow400:'_93txP',yellow200:'_3_Ks3',yellow300:'_3HNL-',yellow100:'_2tdM1',transparent:'_1v46n',currentColor:'_1Dbbv',seagreen100:'_3Xhj0',seagreen200:'TeZRe',seagreen300:'Yl6ym',seagreen400:'_1yRMN',seagreen500:'_35Cij',seagreen600:'KpCWx',seagreen700:'_1eZPt',sg100:'_24SQy',sg200:'_2pKcd',sg300:'_2re_g',sg400:'esLca',sg500:'_1K6Zy',sg600:'_1jTEU',sg700:'_1viLs',sg800:'_2tgiK',sg900:'_2DPEE',sg1000:'_2TSaY',white:'xHnky',black:'_3aQH4'};
export var variantStyles = {'default':'_2XSDd',h1:'lnItr',h2:'IuUb0',h3:'_2RSFA',h4:'_3KPVg',h5:'_1hfCd',small:'_1u3T8',intro:'_15TVT',eyebrow:'_1luGt'};