import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { Box } from "@island.is/core";
import { RichText } from "prismic-reactjs";

interface ShareSiteProps {
  Data: any;
}

const ShareSite = ({ Data }: ShareSiteProps) => {
  return (
    <Box paddingBottom={3}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <RichText render={Data.primary.title} />
      </Box>
      <Box>
        <FacebookShareButton
          url={window.location.href}
          title={"Facebook"}
          quote="Aðalnámskrá grunnskóla"
        >
          <Box className="NavContainer">
            <Box className="NavItem">
              <FacebookIcon round />
            </Box>
          </Box>
        </FacebookShareButton>
        <EmailShareButton
          url={window.location.href}
          title={"Email"}
          subject="Aðalnámskrá grunnskóla"
        >
          <Box className="NavContainer">
            <Box className="NavItem">
              <EmailIcon round />
            </Box>
          </Box>
        </EmailShareButton>
        <TwitterShareButton url={window.location.href} title={"Twitter"}>
          <Box className="NavContainer">
            <Box className="NavItem">
              <TwitterIcon round />
            </Box>
          </Box>
        </TwitterShareButton>
        <LinkedinShareButton
          url={window.location.href}
          source="Aðalnámskrá grunnskóla"
          summary="Aðalnámskrá grunnskóla"
          title={"Linkedin"}
        >
          <Box className="NavContainer">
            <Box className="NavItem">
              <LinkedinIcon round />
            </Box>
          </Box>
        </LinkedinShareButton>
      </Box>
    </Box>
  );
};

export default ShareSite;
