/* 
This is for changing Tooltips from prismic format over to simpler format.
The input data from Prismic is hard to read and complicated 
so we change it into simpler format:
listTooltips: {
	content: Description of the word 
	word: The word
}
*/

function makeTooltips(data: any) {
  var listTooltips = [];
  var i = 0;
  var leni = data.length;
  while (i < leni) {
    var j = 0;
    var lenj = data[i].items.length;
    while (j < lenj) {
      // if the slice is empty then do nothing.
      if (data[i].items[j].word.length === 0) {
        j++;
      } else {
        listTooltips.push({
          content: data[i].primary.content[0].text,
          word: data[i].items[j].word[0].text,
        });
        j++;
      }
    }
    i++;
  }
  return listTooltips;
}

export default makeTooltips;
