import { Box, AsyncSearch } from "@island.is/core";
import { useHistory } from "react-router-dom";

import { items } from "./SearchItem";

const SearchBar = () => {
  let history = useHistory();
  return (
    <Box paddingBottom={1} paddingTop={1}>
      <AsyncSearch
        filter
        closeMenuOnSubmit
        onSubmit={(inputValue) => {
          // eslint-disable-next-line
          if (inputValue !== "" || inputValue === null) {
            inputValue = inputValue.replace(
              // eslint-disable-next-line no-useless-escape
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ""
            );
            history.push(`/leit?q=${inputValue}`);
          }
        }}
        options={items}
        placeholder={"Leita í aðalnámskrá"}
      />
    </Box>
  );
};

export default SearchBar;
