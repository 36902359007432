import "!style-loader!css-loader?{\"modules\":false,\"url\":false}!./Button.treat.2Aq-2ZX.css";
export var isEmpty = '_2RYDn';
export var fluid = 'cDc8V';
export var nowrap = 'dU4wO';
export var variants = {primary:'_3hbN2',ghost:'_1Fs0V',text:'_2tXMT',utility:'_178yo'};
export var size = {'default':'sCFmH',small:'rQhry',large:'AxQ-9',utility:'_3lG9x',textSmall:'_3sSe2'};
export var padding = {text:'_3iJEP','default':'_3fg-_',small:'_3Virq',large:'_1ylXD',utility:'W2IOS'};
export var circleSizes = {'default':'_1XAnO',small:'_2A6NL',large:'_2raem'};
export var colors = {primary:{'default':'_1iErr',destructive:'_1SgE8',negative:'_2u_6w',light:'_29jdL'},ghost:{'default':'Xz3n-',destructive:'zdDYE',negative:'_2d1wl',light:'lAY5G'},text:{'default':'RX-s3',destructive:'_16sQZ',negative:'_3YO5Y',light:'_314bi'},utility:{'default':'_1_8bF',destructive:'_18D3Q',negative:'_1l3OQ',light:'_35H6e'}};
export var circle = '_2LgBf';
export var iconPostText = '_3sIxd';
export var iconPreText = '_3BCFr';
export var icon = '_2A6Vm';
export var loadingCircle = '_2atFa';
export var hideContent = '_3SGAe';
export var loading = 'cv7ih';
export var loader = 'qaNwE';
export var loadingDot = '_2Jytl';