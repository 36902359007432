// Primary colors
export const blue600 = "#0044b3"
export const blue400 = "#0061ff"
export const blue300 = "#99c0ff"
export const blue200 = "#ccdfff"
export const blue100 = "#f2f7ff"

export const dark400 = "#00003c"
export const dark300 = "#9999b1"
export const dark200 = "#ccccd8"
export const dark100 = "#f2f2f5"

export const red600 = "#b30038"
export const red400 = "#ff0050"
export const red300 = "#ff99b9"
export const red200 = "#ffccdc"
export const red100 = "#fff2f6"

export const orange100 = "#FF6F00"

// Support colors
export const blueberry600 = "#24268e"
export const blueberry400 = "#4649d0"
export const blueberry300 = "#b5b6ec"
export const blueberry200 = "#dadbf6"
export const blueberry100 = "#f6f6fd"

export const purple600 = "#421c63"
export const purple400 = "#6a2ea0"
export const purple300 = "#c3abd9"
export const purple200 = "#e1d5ec"
export const purple100 = "#f8f5fa"

export const roseTinted600 = "#4d003a"
export const roseTinted400 = "#9a0074"
export const roseTinted300 = "#d799c7"
export const roseTinted200 = "#ebcce3"
export const roseTinted100 = "#faf2f8"

// Attention colors
export const mint600 = "#00b39e"
export const mint400 = "#00e4ca"
export const mint200 = "#ccfaf4"
export const mint300 = "#99f4ea"
export const mint100 = "#f2fefc"

export const yellow600 = "#e6cf00"
export const yellow400 = "#fff066"
export const yellow200 = "#fffce0"
export const yellow300 = "#fff9c2"
export const yellow100 = "#fffef7"

export const transparent = "transparent"
export const currentColor = "currentColor"

// MMS colors
export const seagreen100 = "#00f8ea"
export const seagreen200 = "#00ded2"
export const seagreen300 = "#00c5ba"
export const seagreen400 = "#00aba2"
export const seagreen500 = "#00928a"
export const seagreen600 = "#007872"
export const seagreen700 = "#005f5a"

export const sg100 = "##e5f6f5"
export const sg200 = "##cceeec"
export const sg300 = "##b2e5e3"
export const sg400 = "##99ddd9"
export const sg500 = "##7fd5d0"
export const sg600 = "##66ccc7"
export const sg700 = "##4cc4bd"
export const sg800 = "##32bbb4"
export const sg900 = "##19b3ab"
export const sg1000 = "##00aba2"

export const white = "#ffffff"
export const black = "##000000"
