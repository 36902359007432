/*
This is a htmlSerilizer from Prismic.
https://prismic.io/docs/technologies/html-serializer-reactjs

Here we add a seagreen color style to h1-h6 tags.  
*/

import React from "react";
import { Elements } from "prismic-reactjs";
import { colors } from "@island.is/constants";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

const seaGreenSerializer = function (type, element, content, children, key) {
  var props = {};
  switch (type) {
    case Elements.heading1: // Heading 1 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h1",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading2: // Heading 2 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h2",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading3: // Heading 3 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h3",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading4: // Heading 4 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h4",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading5: // Heading 5 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h5",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading6: // Heading 6 add Id of title
      props = { style: { color: colors.seagreen600 } };
      return React.createElement(
        "h6",
        propsWithUniqueKey(props, key),
        children
      );
    default:
      return null;
  }
};

export default seaGreenSerializer;
