/*
Loading: Spinning Icon which is render while waiting for
response from a fetch request or component rendering.  
*/

import React from "react";
import { LoadingDots, Box } from "@island.is/core";

import * as styles from "./Loading.treat";

interface LoadingProps {
  ShowSearchbar?: boolean;
}

const Loading = ({ ShowSearchbar = true }: LoadingProps) => {
  return (
    <Box className={styles.root} paddingBottom={30}>
      <LoadingDots large color="blue" />
    </Box>
  );
};

export default Loading;
