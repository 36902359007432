import React, { useEffect, useState } from "react"
import { Header } from "../../components"
import Nav from "../../components/BlogPage/Nav"
import Footer from "../../components/BlogPage/Footer"

import { fetch } from "../../hooks"

const EndurskodunGreinasvida = () => {
  const { doc } = fetch({ url: "endurskodun-greinasvida" })
  const [content, setContent] = useState<any>()

  useEffect(() => {
    if (doc) {
      setContent(doc)
    }
  }, [doc])

  return (
    <>
      <Header />
      <Nav />
      <div className="blog-container">
        <h1>Endurskoðun greinasviða</h1>
        {content && (
          <>
            {content?.map((article, index) => {
              return (
                <div className="blog-wrapper-d" key={index}>
                  {/* blog heading */}
                  <h2>{article.data.main_title[0].text}</h2>
                  {/* blog normal paragraph contents */}
                  {article.data.body.map((body: any, index) => {
                    return (
                      <div key={index}>
                        {body.slice_type === "normal_text" && (
                          <>
                            {body.items.map((t) =>
                              t.text.map((o, index) => {
                                return (
                                  <div key={index}>
                                    <p>{o.text}</p>
                                  </div>
                                )
                              })
                            )}
                          </>
                        )}
                        {/* image contents */}
                        {body.slice_type === "image" && (
                          <>
                            {body.items.map((image, index) => {
                              return (
                                <div className="image-wrapper" key={index}>
                                  <img
                                    src={image.img.url}
                                    alt={image.img.alt}
                                  />
                                </div>
                              )
                            })}
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default EndurskodunGreinasvida
