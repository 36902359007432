/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import Prismic from "@prismicio/client";
import { Client } from "../../prismic";

interface fetchProps {
  url: string;
  lang?: "en-gb" | "en-us";
}

const fetch = ({ url, lang = "en-gb" }: fetchProps) => {
  const [doc, setDocData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      switch (true) {
        case lang === "en-gb":
          try {
            const response = await Client.query(
              Prismic.Predicates.at("document.tags", [url]),
              { pageSize: 100, lang: lang }
            );
            if (response) {
              setDocData(response.results);
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
          break;

        case lang === "en-us":
          try {
            const response = await Client.query(
              Prismic.Predicates.at("document.tags", [url + "-en"]),
              { pageSize: 100, lang: lang }
            );
            if (response) {
              setDocData(response.results);
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
      }
    };

    fetchData();
  }, [url, lang]);

  return { doc, isLoading };
};

export default fetch;
